import React, { useState, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import { useLocation, Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Dropdown,
} from 'react-bootstrap'
import axiosGlobal from '../../utils/axios'
import GoogleAutoSuggest from '../../components/autoSuggest/googleAutoSuggest'
import AutoSuggest from '../../components/autoSuggest/autoSuggest'
import CompanyListing from '../../components/companyListing/companyListing'
import { decodeHtml } from '../../utils/utils'
import geoalt from '../../assets/images/icons/geo-alt-fill.svg'
import search from '../../assets/images/icons/search.svg'
import sortIcon from '../../assets/images/icons/sort-arrow-up.svg'
import featureImage from '../../assets/images/listing-banner.jpg'
import './company.css'
import AutoComplete from '../../components/autoSuggest/autoComplete'

class Company {
  constructor(name, image) {
    this.name = name
    this.image = image
  }
}

const Search = () => {
  const location = useLocation()

  let passedParams
  try {
    passedParams = JSON.parse(location.state)
  } catch {
    passedParams = location.state
  }

  console.log(`passedParams ${JSON.stringify(passedParams)}`)
  const [companies, setCompanies] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [showSorts, setShowSorts] = useState(false)
  const companySearchTimer = useRef()
  const companyName = useRef(passedParams ? passedParams.companyName : '')
  const pickupLocation = useRef(passedParams ? passedParams.pickup : {})
  const dropoffLocation = useRef(passedParams ? passedParams.dropoff : {})
  const vehicleType = useRef(passedParams ? passedParams.vehicleType : '')

  const [params, setParams] = useState({ ...passedParams, default: 1 })
  const noOfColumns = 3

  const searchFormAnimation = useSpring({
    height: showFilters ? '130px' : '0px',
    opacity: showFilters ? 1 : 0,
    transform: showFilters ? 'translate(0px, 0px)' : 'translate(0px, -50px)',
    from: {
      height: showFilters ? '0px' : '130px',
      opacity: showFilters ? 0 : 1,
      transform: showFilters ? 'translate(0px, -50px)' : 'translate(0px, 0px)',
    },
    config: { duration: 300 },
  })

  const fetchComapnies = async (searchTerm) => {
    const response = await axiosGlobal.get(
      `/api/v1/company/search?searchterm=${searchTerm}`,
    )
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const companies = response.data.map((com) => {
        let attachment = com.attachment
          .filter(
            (attch) => attch.type == 'images' || attch.type == 'post_images' || attch.type == 'cover_image',
          )
          .sort((a, b) => a.ID > b.ID)

        attachment = attachment.length > 0 ? attachment[0] : null

        const featureImage = attachment
          ? attachment.file
          : '2021/10/default.jpg'
        return new Company(com.post_title, featureImage)
      })

      setCompanies(companies)
    }
  }

  const searchCompanies = () => {
    setParams((obj) => ({
      ...obj,
      pickup: pickupLocation.current,
      dropoff: dropoffLocation.current,
      vehicleType: vehicleType.current,
      companyName: companyName.current,
    }))
    setShowFilters(false)
  }

  const clearAllFilters = () => {
    pickupLocation.current = {}
    dropoffLocation.current = {}
    vehicleType.current = ''
    companyName.current = ''
    setParams({ featured: 1 })
  }

  const getSearchTags = () => {
    const titles = {
      pickup: 'Pick up City',
      dropoff: 'Drop off City',
      vehicleType: 'Vehicle Type',
      companyName: 'Company Name',
    }
    return Object.keys(params)
      .filter(
        (key) =>
          params[key] &&
          titles[key] &&
          (typeof params[key] === 'string' ||
            (typeof params[key] === 'object' &&
              Object.keys(params[key]).length > 0)),
      )
      .map((key) => (
        <div className="search-tags">
          <i
            className="fa fa-close"
            onClick={() => {
              if (key == 'pickup') {
                pickupLocation.current = {}
              } else if (key == 'dropoff') {
                dropoffLocation.current = {}
              } else if (key == 'vehicleType') {
                vehicleType.current = ''
              } else if (key == 'companyName') {
                companyName.current = ''
              }
              setParams((obj) => ({
                ...obj,
                [key]: key == 'vehicleType' || 'companyName' ? '' : {},
              }))
            }}
          ></i>
          {titles[key]}: {getTileValue(key)}
        </div>
      ))
  }

  const getFilterTag = () => {
    let title = ''
    if (params.featured) {
      title = 'Featured'
    } else if (params.isRandom) {
      title = 'Random'
    } else if (params.fleet_size) {
      title = 'Fleet Size'
    } else if (params.default) {
      title = ''
    }

    return !params.default ? (
      <div className="search-tags">
        <Image src={sortIcon} className="fa-sort" />
        {title}
        <i
          className="fa fa-close"
          onClick={() => {
            setParams((obj) => {
              const newSortObj = { ...obj }
              delete newSortObj.default
              //delete newSortObj.featured
              delete newSortObj.isRandom
              delete newSortObj.fleet_size

              //newSortObj.default = 1
              newSortObj.featured = 1

              return newSortObj
            })
          }}
        ></i>
      </div>
    ) : null
  }

  const getTileValue = (key) => {
    if (key == 'pickup' || key == 'dropoff') {
      return params[key].location
    }
    return params[key]
  }

  const setSelectedSort = (eventKey) => {
    console.log(`setSelectedSort ${eventKey}`)
    setParams((params) => {
      const newSortObj = { ...params }
      delete newSortObj.default
      delete newSortObj.featured
      delete newSortObj.isRandom
      delete newSortObj.fleet_size
      if (eventKey == 'featured') {
        newSortObj['featured'] = 1
      } else if (eventKey == 'random') {
        newSortObj['isRandom'] = 1
      } else if (eventKey == 'fleet-size') {
        newSortObj['fleet_size'] = 1
      } else if (eventKey == 'default') {
        newSortObj['default'] = 1
      }
      return newSortObj
    })
  }

  return (
    <Container fluid>
      <Row className="align-items-center listing-hero">
        <div className="detail-title" style={{ bottom: '30px' }}>
          BROWSE COMPANY
        </div>
      </Row>
      <Row className="listing-body">
        <Row className="listing-body-top">
          <Row
            className="justify-content-center mt-5 mb-3"
            style={{ zIndex: 2003 }}
          >
            <Col lg={6} md={6} sm={6} xs={6} className="d-flex">
              <Button
                className={`text-white listing-search-form-apply-btn ${
                  showFilters ? '' : 'active'
                }`}
                onClick={() => setShowFilters((val) => !val)}
              >
                Filter
                <i
                  className="fa fa-regular fa-sliders"
                  style={{ marginLeft: '10px' }}
                ></i>
              </Button>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="d-flex justify-content-end"
            >
              <Dropdown
                onSelect={(eventKey, event) => {
                  console.log(`eventKey ${eventKey}`)
                  setSelectedSort(eventKey)
                }}
                onToggle={(val) => setShowSorts(val)}
              >
                <Dropdown.Toggle
                  className={`text-white listing-search-form-apply-btn ${
                    showSorts ? '' : 'active'
                  }`}
                  id="dropdown-basic"
                >
                  Sort by
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="default">
                    <div className="sort-dropdown-menu">
                      <Form.Check
                        type="radio"
                        id="default"
                        checked={params.default === 1}
                        onChange={() => setSelectedSort('default')}
                        disabled
                      />
                      Default
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item eventKey="random">
                    <div className="sort-dropdown-menu">
                      <Form.Check
                        type="radio"
                        id="random"
                        checked={params.isRandom === 1}
                        onChange={() => setSelectedSort('random')}
                        disabled
                      />
                      Random
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="featured">
                    <div className="sort-dropdown-menu">
                      <Form.Check
                        type="radio"
                        id="featured"
                        checked={params.featured === 1}
                        onChange={() => setSelectedSort('featured')}
                        disabled
                      />
                      Featured
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="fleet-size">
                    <div
                      className="sort-dropdown-menu"
                      onClick={() => setSelectedSort('fleet-size')}
                    >
                      <Form.Check
                        type="radio"
                        id="fleet-size"
                        checked={params.fleet_size === 1}
                        onChange={() => setSelectedSort('fleet-size')}
                        disabled
                      />
                      Fleet Size
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row style={{ zIndex: showFilters ? 2002 : 2000 }}>
            <animated.div style={searchFormAnimation}>
              <Col lg={7} sm={12} xs={12} className="mb-2 listing-search-form">
                <Row className="search-form-row">
                  <Col lg={5} sm={12} xs={12}  style={{zIndex: 999, position: 'relative'}}>
                    <div className="input-wrapper">
                      <div className="input-left">Pickup</div>
                      <div className="input-right">
                        <GoogleAutoSuggest
                          placeholder="Pick up Location"
                          bgcolor="#F0F0F0"
                          getSelectedValue={(value) => {
                            pickupLocation.current = value
                            //setParams((obj) => ({ ...obj, pickup: value }))
                          }}
                          onChange={(value) => {
                            pickupLocation.current = value
                          }}
                          value={
                            pickupLocation.current &&
                            pickupLocation.current.location
                              ? pickupLocation.current.location
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={5} sm={12} xs={12} className="mt-2 mt-md-0" style={{zIndex: 998, position: 'relative'}}>
                    <div className="input-wrapper">
                      <div className="input-left">Drop</div>
                      <div className="input-right">
                        <GoogleAutoSuggest
                          placeholder="Drop off Location"
                          bgcolor="#F0F0F0"
                          getSelectedValue={(value) => {
                            dropoffLocation.current = value
                          }}
                          onChange={(value) => {
                            dropoffLocation.current = value
                          }}
                          value={
                            dropoffLocation.current &&
                            dropoffLocation.current.location
                              ? dropoffLocation.current.location
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={1}
                    sm={12}
                    xs={12}
                    className="d-none d-md-block"
                    style={{ marginLeft: 'auto' }}
                  >
                    <Button
                      className="listing-search-form-btn listing-search-form-clear-btn"
                      onClick={() => clearAllFilters()}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
                <Row className="search-form-row mt-2 mt-md-3" style={{zIndex: 900, position: 'relative'}}>
                  <Col lg={5} sm={12} xs={12} style={{zIndex: 1000}}>
                    <div className="input-wrapper">
                      <div className="input-left">Company</div>
                      <div className="input-right">
                        {console.log(
                          `params.companyName ${params.companyName}`,
                        )}

                        {
                          /*
                                             <AutoSuggest
                          id="company"
                          data={companies}
                          initialVal={params.companyName}
                          icon={search}
                          bgcolor="white"
                          color="black"
                          placeholder="Enter company name"
                          onChange={(value) => {
                            clearTimeout(companySearchTimer.current)
                            companySearchTimer.current = setTimeout(() => {
                              fetchComapnies(value)
                            }, 500)
                          }}
                          renderSuggestion={(suggestion) => (
                            <div className="company-render-box">
                              <Image
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  marginRight: '10px',
                                }}
                                src={
                                  suggestion.image
                                    ? `/uploads/${suggestion.image}`
                                    : '/uploads/2021/10/default.jpg'
                                }
                              />
                              {decodeHtml(suggestion.name)}
                            </div>
                          )}
                          getSuggestionValue={(suggestion) => {
                            companyName.current = suggestion.name
                            return suggestion.name
                          }}
                        />
                          */
                        }

                        <AutoComplete
                          initialValue={params.companyName}
                          data={companies}
                          onSearch={(value) => {
                            fetchComapnies(value)
                          }}
                          onSelect={(value) => {
                            companyName.current = value[0].name
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={5} sm={12} xs={12} className="mt-2 mt-md-0">
                    <div className="input-wrapper">
                      <div className="input-left">Vehicle Type</div>
                      <div className="input-right">
                        <Form.Group controlId="formPickUpLocation">
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(event) =>
                              (vehicleType.current = event.target.value)
                            }
                            style={{ backgroundColor: '#F0F0F0' }}
                          >
                            <option
                              value=""
                              id="select-first"
                              style={{ backgroundColor: '#3c434a' }}
                              selected={!vehicleType.current}
                            >
                              Select Vehicle Type
                            </option>
                            <option
                              value="Sedan"
                              selected={vehicleType.current == 'Sedan'}
                            >
                              Sedan
                            </option>
                            <option
                              value="Luxury Sedan"
                              selected={vehicleType.current == 'Luxury Sedan'}
                            >
                              Luxury Sedan
                            </option>
                            <option
                              value="Van"
                              selected={vehicleType.current == 'Van'}
                            >
                              Van
                            </option>
                            <option
                              value="Luxury Van"
                              selected={vehicleType.current == 'Luxury Van'}
                            >
                              Luxury Van
                            </option>
                            <option
                              value="Mini-Van"
                              selected={vehicleType.current == 'Mini-Van'}
                            >
                              Mini-Van
                            </option>
                            <option
                              value="Executive Van"
                              selected={vehicleType.current == 'Executive Van'}
                            >
                              Mini-Van
                            </option>
                            <option
                              value="Stretch"
                              style={{ backgroundColor: '#3c434a' }}
                              selected={vehicleType.current == 'Stretch'}
                            >
                              Stretch
                            </option>
                            <option
                              value="Coach"
                              selected={vehicleType.current == 'Coach'}
                            >
                              Coach
                            </option>
                            <option
                              value="Mini-Bus"
                              selected={vehicleType.current == 'Mini-Bus'}
                            >
                              Mini-Bus
                            </option>
                            <option
                              value="Limo Bus"
                              selected={vehicleType.current == 'Limo Bus'}
                            >
                              Limo Bus
                            </option>
                            <option
                              value="SUV"
                              style={{ backgroundColor: '#3c434a' }}
                              selected={vehicleType.current == 'SUV'}
                            >
                              SUV
                            </option>
                            <option
                              value="Specialty"
                              selected={vehicleType.current == 'Specialty'}
                            >
                              Specialty
                            </option>
                            <option
                              value="Hybrid"
                              selected={vehicleType.current == 'Hybrid'}
                            >
                              Hybrid
                            </option>
                            <option
                              value="Alternative Fuel"
                              selected={
                                vehicleType.current == 'Alternative Fuel'
                              }
                            >
                              Alternative Fuel
                            </option>
                            <option
                              value="Electric"
                              selected={vehicleType.current == 'Electric'}
                            >
                              Electric
                            </option>
                            <option
                              value="Wheelchair Accessible"
                              selected={
                                vehicleType.current == 'Wheelchair Accessible'
                              }
                            >
                              Wheelchair Accessible
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={1}
                    sm={12}
                    xs={12}
                    className="d-none d-md-block"
                    style={{ marginLeft: 'auto' }}
                  >
                    <Form.Group controlId="formDropOffLocation">
                      <Button
                        className="text-white listing-search-form-btn listing-search-form-apply-btn"
                        onClick={searchCompanies}
                      >
                        Apply
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="search-form-action-mobile">
                  <Col sm={4} xs={4}></Col>
                  <Col sm={4} xs={4} className="d-flex justify-content-end">
                    <Button
                      className="listing-search-form-btn listing-search-form-clear-btn"
                      onClick={() => clearAllFilters()}
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col sm={4} xs={4} className="d-flex justify-content-end">
                    <Form.Group className="" controlId="formDropOffLocation">
                      <Button
                        className="text-white listing-search-form-btn listing-search-form-apply-btn"
                        onClick={searchCompanies}
                      >
                        Apply
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </animated.div>
          </Row>

          <Row className="d-flex align-items-center" style={{ zIndex: 2001 }}>
            <Col lg={8} sm={7} xs={7} className="d-flex mt-3 mb-4">
              <div className="search-tag-wrapper">{getSearchTags()}</div>
            </Col>
            <Col
              lg={4}
              sm={5}
              xs={5}
              className="d-flex justify-content-end mt-3 mb-4"
            >
              {getFilterTag()}
            </Col>
          </Row>
          {/*<Row
            className="d-block d-md-none"
            style={{ height: showFilters ? '100px' : '0px' }}
          ></Row>*/}
        </Row>
        <Row className="listing-body-main">
          <CompanyListing params={params} noOfColumns={noOfColumns} />
        </Row>
      </Row>
    </Container>
  )
}

export default Search
