import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import twitter from '../../assets/images/icons/twitter.svg'
import facebook from '../../assets/images/icons/facebook.svg'
import linkedin from '../../assets/images/icons/linkedin.svg'
import './footer.css'

const Footer = () => {
  return (
    <Container fluid className="footer-container">
      <Row className="pt-5 pb-3 d-flex justify-content-center">
        <Col lg={1} sm={4} xs={4}>
          <Image src={twitter} alt="twitter" width="32" height="32" />
        </Col>
        <Col lg={1} sm={4} xs={4}>
          <Image src={facebook} alt="facebook" width="32" height="32" />
        </Col>
        <Col lg={1} sm={4} xs={4}>
          <Image src={linkedin} alt="linkedin" width="32" height="32" />
        </Col>
      </Row>
      <Row className="p-3">
        <Col>
          Disclaimer:{' '}
          <a href="#" className="footer-link">
            The National Limousine Association
          </a>
          , Inc. (NLA) makes the information on this website available for the
          benefit of its members and the public in furtherance of its non-profit
          and tax-exempt purposes. While the NLA makes every effort to present
          accurate and reliable information on this site, the NLA does not
          endorse, approve, or certify any of the companies or services whose
          information is contained on this site, nor does it guarantee the
          accuracy, completeness, efficacy, or timeliness of any of the
          information.
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={2}>
          <a href="#" className="footer-link">
            Terms & Conditions
          </a>
        </Col>
        <Col sm={2}>
          <a href="#" className="footer-link">
            Privacy Policy
          </a>
        </Col>
        <Col sm={2}>
          <a href="#" className="footer-link">
            Trademark Use Guidelines
          </a>
        </Col>
      </Row>
      <Row className="mt-5 pb-5">
        <Col>Copyright © 2022 National Limousine Association</Col>
      </Row>
    </Container>
  )
}
export default Footer
