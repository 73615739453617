import React, { useState } from 'react'
import { Form, Image, Button } from 'react-bootstrap'
import axiosGlobal from '../../utils/axios'

const ContactForm = (props) => {
  const [fields, setFields] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const submitForm = async () => {
    const emptyField = Object.values(fields).find((f) => {
      return f.length == 0
    })

    if (emptyField == undefined) {
      setError('')

      const response = await axiosGlobal.post(`/api/v1/company/contact`, {
        ...fields,
        memberemail: props.memberEmail,
      })
      if (
        response.statusText == 'OK' ||
        response.status == 200 ||
        response.status == 304
      ) {
        setSuccess('Sent successfully')
      } else {
        setSuccess('')
      }
    } else {
      setSuccess('')
      setError('All fields are required')
    }
  }

  const setFormValues = (name, value) => {
    setFields((values) => ({ ...values, [name]: value }))
  }

  return (
    <Form>
      <Form.Group controlId="contactForm">
        <Form.Label className="form-title">Your Name</Form.Label>
        <Form.Control
          className="mb-3"
          type="text"
          placeholder="Name"
          value={fields.name}
          onChange={(val) => setFormValues('name', val.target.value)}
        />
        <Form.Label className="form-title">Email address</Form.Label>
        <Form.Control
          className="mb-3"
          type="email"
          placeholder="Email"
          value={fields.email}
          onChange={(val) => setFormValues('email', val.target.value)}
        />
        <Form.Label className="form-title">Phone number</Form.Label>
        <Form.Control
          className="mb-3"
          type="number"
          placeholder="Phone"
          value={fields.phone}
          onChange={(val) => setFormValues('phone', val.target.value)}
        />
        <Form.Label className="form-title">Message</Form.Label>
        <Form.Control
          className="mb-3"
          type="text"
          placeholder="Message"
          rows={5}
          as="textarea"
          value={fields.message}
          onChange={(val) => setFormValues('message', val.target.value)}
        />
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {error ? error : ''}
        </div>
        <div style={{ color: 'green', marginBottom: '10px' }}>
          {success ? success : ''}
        </div>
      </Form.Group>

      <Button onClick={submitForm} className="p-5 pt-2 pb-2 text-white submit">
        Submit
      </Button>
    </Form>
  )
}

export default ContactForm
