import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import axiosGlobal from "../../utils/axios";
import LighBoxGallary from "../../components/companyDetail/lighBoxGallary";
import ContactForm from "../../components/companyDetail/contactForm";
import { decodeHtml } from "../../utils/utils";
import ClaimListing from "../../components/companyDetail/claimListing";
import ToastMesage from "../../components/companyDetail/toast";
import "./company.css";

import heroImage from "../../assets/images/sample_company_detail.png";

const CompanyDetail = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth);

  const [company, setCompany] = useState({});
  const [showClaimListingModal, setShowClaimListingModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [upgradeStatus, setUpgradeStatus] = useState(false);

  const parsedMemberId = new URLSearchParams(location.search).get("memberId");
  let isProfileViewMode = new URLSearchParams(location.search).get("profile");
  isProfileViewMode =
    isProfileViewMode && user.listing_id == company.web_site_member_id ? 1 : 0;

  useEffect(() => {
    fetchCompanyDetail();
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (user.isLoggedIn && parsedMemberId != null) {
      upgradeListing();
    }
  }, [parsedMemberId, user]);

  const upgradeListing = async () => {
    try {
      const response = await axiosGlobal.post(`/api/v1/company/upgrade`, {
        memberid: parsedMemberId,
        email: user.userEmail ? user.userEmail : "test email",
        name: user.userDisplayName ? user.userDisplayName : "test name",
      });
      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304
      ) {
        localStorage.setItem("is_listing_upgraded", true);
        setUpgradeStatus(true);
      }

      setShowToast(true);
    } catch (e) {
      setShowToast(true);
    }
  };

  const fetchCompanyDetail = async () => {
    const response = await axiosGlobal.get(`/api/v1/company/get/${id}`);
    if (
      response.statusText == "OK" ||
      response.status == 200 ||
      response.status == 304
    ) {
      if(response.data.claimed == 1 && response.data.package_id != 2) {
        localStorage.setItem("is_listing_upgraded", false);
      }
      setCompany(response.data);
    }
  };

  let featureImage = "";
  let logoUrl = "";
  let gallaryImages = [];
  if (company && company.attachment) {
    featureImage = company.attachment.find(
      (attch) => attch.type == "cover_image"
    )?.file;

    if (!featureImage) {
      let attachment = company.attachment
        .filter(
          (attch) => attch.type == "images" || attch.type == "post_images"
        )
        .sort((a, b) => a.ID > b.ID);

      attachment = attachment.length > 0 ? attachment[0] : null;
      featureImage = attachment ? attachment.file : "/2021/10/default.jpg";
    }

    const firstLogoUrl = company.attachment.find(
      (attch) => attch.type == "logo"
    );
    logoUrl = firstLogoUrl ? firstLogoUrl.file : "/2021/10/default.jpg";
    gallaryImages = company.attachment
      .filter(
        (attch) =>
          attch.type == "post_images" ||
          attch.type == "images" ||
          attch.type == "cover_image"
      )
      .map((imgData) => ({
        id: imgData.ID,
        url: `${process.env.REACT_APP_S3_IMAGE_URL}${imgData.file}`,
      }));
  }

  const editListing = () => {
    navigate(`/companyEditListing/${id}`);
  };

  const showAllFields = () => {
    return (
      <>
        <Row className="mt-4">
          <Col lg={3} sm={3}>
            <div className="detail-field-lable">Is Featured</div>
          </Col>
          <Col lg={8} sm={8}>
            <div className="detail-field-value">
              {company.featured ? "Yes" : "NO"}
            </div>
          </Col>
        </Row>
        {company.fleet_size && (
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Fleet Size</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.fleet_size ? company.fleet_size : "NA"}
              </div>
            </Col>
          </Row>
        )}

        {company.latitude && company.longitude && (
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Coordinates</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.latitude ? company.latitude : "NA"},{" "}
                {company.longitude ? company.longitude : "NA"}
              </div>
            </Col>
          </Row>
        )}

        {company.pick_up_city && (
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Pickup City</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.pick_up_city ? company.pick_up_city : "NA"}
              </div>
            </Col>
          </Row>
        )}

        {company.drop_off_city && (
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Dropoff City</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.drop_off_city ? company.drop_off_city : "NA"}
              </div>
            </Col>
          </Row>
        )}

        <Row className="mt-4">
          <Col lg={3} sm={3}>
            <div className="detail-field-lable">Airports Serviced</div>
          </Col>
          <Col lg={8} sm={8}>
            <div className="detail-field-value">
              {company.airports_serviced ? (
                <ul style={{ marginLeft: "-20px" }}>
                  {company.airports_serviced
                    .split(",")
                    .filter((airport) => airport)
                    .map((airport) => (
                      <li>{airport}</li>
                    ))}
                </ul>
              ) : (
                "NA"
              )}
            </div>
          </Col>
        </Row>
        {company.expire_date && (
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Expiry Date</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.expire_date ? company.expire_date : "NA"}
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const emails = company?.email ? company?.email.split(",") : [""];
  const email = emails.length > 0 ? emails[0] : "";

  return (
    <Container
      fluid
      className={isProfileViewMode ? `profile-container` : `detail-container`}
    >
      <div className="toast">
        <ToastMesage
          show={showToast}
          success={upgradeStatus}
          message={
            upgradeStatus ? "Upgraded successfully" : "Failed to Upgrade"
          }
          closeToast={() => setShowToast(false)}
        />
      </div>
      <Row
        style={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${process.env.REACT_APP_S3_IMAGE_URL}${featureImage})`,
          //backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${heroImage})`,
        }}
        className="align-items-center detail-hero"
      >
        <div className="detail-title">{decodeHtml(company.post_title)}</div>
      </Row>
      <Row className="d-flex justify-content-center p-5 mt-5">
        <Col lg={isProfileViewMode ? 11 : 7} sm={12}>
          <Row>
            <div class="d-flex justify-content-between align-items-center">
              <div className="contact-title mb-2">
                {isProfileViewMode ? "Profile" : "Contact this member"}
              </div>
              {isProfileViewMode && (
                <div>
                  <Button
                    className="text-white listing-search-form-apply-btn px-4"
                    onClick={() => editListing()}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>
            <div style={{ textAlign: "left" }}>
              <img
                height={70}
                /*width={300}*/ src={`${process.env.REACT_APP_S3_IMAGE_URL}${logoUrl}`}
              />
            </div>
          </Row>
          <Row className="mt-5">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Contact</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.full_name ? company.full_name : "NA"}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Phone Number</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                <a href={`tel:${company.phone_number}`}>
                  {company.phone_number}
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Address</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">{`${company.street},${company.city}, ${company.region}, ${company.country}, ${company.zip}`}</div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Email</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                <a href={`mailto:${email}`}>{email}</a>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Website</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                <a
                  style={{ textDecoration: "none" }}
                  href={company.website}
                  target="_blank"
                >
                  {company.website ? company.website : "NA"}
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Cities Serviced</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.cities_serviced_clean ? (
                  <ul style={{ marginLeft: "-20px" }}>
                    {company.cities_serviced_clean
                      .split(" | ")
                      .filter((city) => city)
                      .map((city) => (
                        <li>{city}</li>
                      ))}
                  </ul>
                ) : (
                  "NA"
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Is Gnet User?</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.is_gnet_user ? company.is_gnet_user : "NA"}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Software Providers</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.software ? (
                  <ul style={{ marginLeft: "-20px" }}>
                    {company.software.split(",").map((software) => (
                      <li>{software}</li>
                    ))}
                  </ul>
                ) : (
                  "NA"
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Vehicle Types Available</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.cf1632143598 ? (
                  <ul style={{ marginLeft: "-20px" }}>
                    {company.cf1632143598.split(",").map((vType) => (
                      <li>{vType}</li>
                    ))}
                  </ul>
                ) : (
                  "NA"
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} sm={3}>
              <div className="detail-field-lable">Regional Association</div>
            </Col>
            <Col lg={8} sm={8}>
              <div className="detail-field-value">
                {company.regional_association ? (
                  <ul style={{ marginLeft: "-20px" }}>
                    {company.regional_association.split(",").map((ra) => (
                      <li>{ra}</li>
                    ))}
                  </ul>
                ) : (
                  "NA"
                )}
              </div>
            </Col>
          </Row>

          {isProfileViewMode ? showAllFields() : null}

          <Row className="mt-4">
            {((company.posts && user.userId == company.posts.post_author) ||
              user.isAdmin) &&
            !isProfileViewMode ? (
              <Col
                sm={12}
                xs={12}
                md={4}
                lg={/*!company.claimed ? 6 : 12*/ 4}
                className=" d-flex justify-content-center mb-3 p-2"
              >
                <Button
                  className="text-white listing-search-form-apply-btn w-100"
                  onClick={() => editListing()}
                >
                  Edit Listing
                </Button>
              </Col>
            ) : null}
            {!isProfileViewMode && user.isLoggedIn && !company.claimed ? (
              <Col
                sm={12}
                xs={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center mb-3 p-2"
              >
                <Button
                  className="text-white listing-search-form-apply-btn w-100"
                  onClick={() => setShowClaimListingModal(true)}
                >
                  Claim Listing
                </Button>
              </Col>
            ) : null}
            {!isProfileViewMode &&
            company.package_id != 2 &&
            ((company.posts && user.userId == company.posts.post_author) ||
              user.isAdmin) ? (
              <Col
                sm={12}
                xs={12}
                md={4}
                lg={/*!company.claimed ? 6 : 12*/ 4}
                className=" d-flex justify-content-center mb-3 p-2"
              >
                <Button
                  className="text-white listing-search-form-apply-btn w-100"
                  onClick={() => {
                    window.location.href = `https://form.jotform.com/213116362630142?companyId=${id}&memberId=${company.web_site_member_id}`;
                    //window.location.href = `https://form.jotform.com/221953028899065?companyId=${id}&memberId=${company.web_site_member_id}`
                  }}
                >
                  Upgrade Listing
                </Button>
              </Col>
            ) : null}
          </Row>
        </Col>
        {!isProfileViewMode && (
          <Col lg={4} sm={12} className="p-4 contact-form">
            <ContactForm memberEmail={email} />
          </Col>
        )}
      </Row>
      <Row className="d-flex justify-content-center p-3 mt-5">
        <LighBoxGallary
          images={gallaryImages}
          editHandler={editListing}
          isProfileViewMode={isProfileViewMode}
        />
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col lg={2} sm={4} className="mb-5">
          {isProfileViewMode && user.isLoggedIn && !company.claimed ? (
            <Button
              className="text-white listing-search-form-apply-btn"
              style={{ width: "200px" }}
              onClick={() => setShowClaimListingModal(true)}
            >
              <i class="fa fa-pencil-square-o me-2" aria-hidden="true"></i>
              Claim Listing
            </Button>
          ) : null}
          <div className="my-3"></div>
          {isProfileViewMode &&
          company.package_id != 2 &&
          ((company.posts && user.userId == company.posts.post_author) ||
            user.isAdmin) ? (
            <Button
              className="text-white listing-search-form-apply-btn"
              style={{ width: "200px" }}
              onClick={() => {
                window.location.href = `https://form.jotform.com/213116362630142?companyId=${id}&memberId=${company.web_site_member_id}`;
              }}
            >
              <i class="fa fa-upload me-2" aria-hidden="true"></i>
              Upgrade Listing
            </Button>
          ) : null}
        </Col>
      </Row>
      <ClaimListing
        show={showClaimListingModal}
        handleModalClose={() => setShowClaimListingModal(false)}
        company={company}
        userEmail={user.userEmail}
      />
    </Container>
  );
};

export default CompanyDetail;
