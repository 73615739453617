import { Button, Col, Container, Row } from 'react-bootstrap'
import Events from "../../components/events/events"
import EventsSlider from '../../components/eventSlider/eventsSlider'

const getScreenSpecificComponent = () => {
  return window.innerWidth > 576 ? <Events /> : <EventsSlider />
}
const EventsSection = () => {
  return (
    <Container fluid className="full-screen-height events-section">
      <Row>
        <div className="search-title white-title left-title">Events</div>
      </Row>
      <Row>
        <Events />
      </Row>
    </Container>
  )
}

export default EventsSection