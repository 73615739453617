import React, { useEffect, useState } from 'react'
import { Col, Card, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import axiosGlobal from '../../utils/axios'

class Blog {
  constructor(id, author, title, shortContent, content, image, created_on) {
    this.id = id
    this.author = author
    this.title = title
    this.shortContent = shortContent
    this.content = content
    this.image = image
    this.created_on = created_on
  }
}

const Blogs = () => {
  useEffect(() => {
    fetchBlogs()
  }, [])

  const navigate = useNavigate()

  const [blogs, setBlogs] = useState([])

  const fetchBlogs = async () => {
    const response = await axiosGlobal.get('/api/v1/blog/list?page=1&limit=100')
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const blogs = response.data
        .filter((blog) => blog.thumbnail)
        .map(
          (blog) =>
            new Blog(
              blog.ID,
              blog.auther,
              blog.title,
              blog.content.slice(0, 100) + '...',
              blog.content,
              blog.thumbnail,
              blog.created_on,
            ),
        )
      setBlogs(blogs)
    }
  }

  const goToDetail = (id, index) => {
    navigate(`/blogDetail/${id}`, {
      state: {
        currentBlogId: blogs[index].id,
      },
    })
  }

  const getCard = () => {
    return blogs.map((blog, index) => (
      <Card
        className="m-3 card-main"
        onClick={() => goToDetail(blog.id, index)}
      >
        <Card.Img variant="top" src={`${process.env.REACT_APP_S3_IMAGE_URL}/${blog.image}`} />
        <Card.Body>
          <Card.Title className="card-title-first">Blog</Card.Title>
          <Card.Title className="card-title-second">{blog.title}</Card.Title>
          <Card.Text className="card-content">
            <div dangerouslySetInnerHTML={{ __html: blog.shortContent }}></div>
          </Card.Text>
        </Card.Body>
      </Card>
    ))
  }

  return (
    <Container>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry style={{ marginTop: '100px' }}>{getCard()}</Masonry>
      </ResponsiveMasonry>
    </Container>
  )
}

export default Blogs
