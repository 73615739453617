import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import axiosGlobal from "../utils/axios";
import {
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import arrowLeft from "../assets/images/icons/arrow-left.svg";
import "./auth.css";
import Alert from "../components/alert/alert";
import ToastMesage from "../components/companyDetail/toast";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const isSignin = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [isForgotMode, setIsForgotMode] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showRegisterAlert, setShowRegisterAlert] = useState(false);
  const emailField = useRef();
  const oauthProfile = useRef(null);
  const authCode = useRef(null);

  const passwordField = useRef();
  const confirmPasswordField = useRef();

  useEffect(() => {
    if (isSignin) {
      localStorage.clear();
      dispatch({ type: "SIGNOUT" });
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/verify")) {
      verifyLink();
    } else if (location.pathname.includes("/resetpassword")) {
      setIsResetMode(true);
    } else {
      setIsRegisterMode(false);
      setIsForgotMode(false);
      setIsResetMode(false);
    }
  }, [uuid]);

  useEffect(() => {
    if (authCode.current != searchParams.get("code")) {
      authCode.current = searchParams.get("code");

      if (authCode && !isLoading) {
        (async () => {
          setIsLoading(true);
          await verifyYMAuthorization(authCode.current);
          setIsLoading(false);
        })();
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!oauthProfile.current && emailField.current && passwordField.current) {
      emailField.current.value = "";
      passwordField.current.value = "";
    }
  }, [isRegisterMode, isForgotMode, isResetMode]);

  const verifyYMAuthorization = async (code) => {
    try {
      const registerParam = searchParams.get("register") ? "&register=1" : "";
      const response = await axiosGlobal.get(
        `/api/v1/user/oauth_callback?code=${code}${registerParam}`,
        {
          timeout: 1000 * 10,
        }
      );

      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304
      ) {
        setShowRegisterAlert(false);

        const user = response.data.user;
        if (response.data.action == "login") {
          makeUserLoggedIn(user, response.data.token);
        } else {
          console.log(`isRegisterMode ${isRegisterMode}`);
          if (!isRegisterMode && !searchParams.get("register")) {
            setShowRegisterAlert(true);
          } else {
            oauthProfile.current = user;
            setIsRegisterMode(true);
          }

          //setIsRegisterMode(true);
          //userNameField.current.value = user.user_email
          //emailField.current.value = user.user_email
          //nameField.current.value = user.display_name
          //console.log(`all good, now`)
        }
      } else {
        setError("Couldn't verify");
      }
    } catch (err) {
      console.log(`err ${JSON.stringify(err)}`);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  //console.log(`isRegisterMode ${isRegisterMode}`);

  const verifyLink = async () => {
    try {
      const response = await axiosGlobal.get(`/api/v1/user/verify/${uuid}`);

      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304
      ) {
      } else {
        setError("Couldn't verify");
      }
    } catch {
      setError("Couldn't verify");
    }

    /*setIsRegisterMode(false)
    setIsForgotMode(false)*/

    navigate("/login");
  };

  const signin = async () => {
    if (
      emailField.current.value.length == 0 ||
      passwordField.current.value.length == 0
    ) {
      setError("Please enter username and password");
      return;
    }

    try {
      const response = await axiosGlobal.post("/api/v1/user/authenticate", {
        username: emailField.current.value,
        password: passwordField.current.value,
      });
      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304
      ) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("userId", JSON.stringify(response.data.user.ID));
        localStorage.setItem(
          "userEmail",
          JSON.stringify(response.data.user.user_email)
        );
        localStorage.setItem(
          "userDisplayName",
          JSON.stringify(response.data.user.display_name)
        );
        localStorage.setItem(
          "isAdmin",
          JSON.stringify(response.data.user.is_admin)
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("listing_id", response.data.listing_id);
        localStorage.setItem("is_listing_upgraded", response.data.is_listing_upgraded);
        dispatch({
          type: "SIGNIN",
          data: {
            userId: response.data.user.ID,
            userEmail: response.data.user.user_email,
            userDisplayName: response.data.user.display_name,
            isAdmin: response.data.user.is_admin,
            token: response.data.token,
            listing_id: response.data.listing_id,
            is_listing_upgraded: response.data.is_listing_upgraded
          },
        });

        navigate("/");
      } else {
        setError("Invalid credentials");
      }
    } catch (e) {
      setError("Invalid credentials");
    }
  };

  const makeUserLoggedIn = (user, token) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", JSON.stringify(user.ID));
    localStorage.setItem("userEmail", JSON.stringify(user.user_email));
    localStorage.setItem("userDisplayName", JSON.stringify(user.display_name));
    localStorage.setItem("isAdmin", JSON.stringify(user.is_admin));
    localStorage.setItem("token", token);
    localStorage.setItem("listing_id", JSON.stringify(user.listing_id));
    localStorage.setItem("is_listing_upgraded", JSON.stringify(user.is_listing_upgraded));

    dispatch({
      type: "SIGNIN",
      data: {
        userId: user.ID,
        userEmail: user.user_email,
        userDisplayName: user.display_name,
        isAdmin: user.is_admin,
        token: user.token,
        listing_id: user.listing_id,
        is_listing_upgraded: user.is_listing_upgraded
      },
    });

    navigate("/");
  };

  const register = async () => {
    if (
      emailField.current.value.length == 0 ||
      passwordField.current.value.length == 0
    ) {
      setError("Please enter all the details");
      return;
    }

    try {
      const response = await axiosGlobal.post("/api/v1/user/register", {
        //username: userNameField.current.value,
        //name: nameField.current.value,
        email: emailField.current.value,
        password: passwordField.current.value,
        isOauth: oauthProfile.current ? true : false,
      });
      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304 ||
        response.status == 201 ||
        response.statusText == "Created"
      ) {
        if (oauthProfile.current) {
          setShowToast(true);
          setIsRegisterMode(false);
        } else {
          setSuccess("Verification link sent to email id");
        }

        //setIsRegisterMode(false)
      } else {
        setError("Something went wrong");
      }
    } catch (e) {
      setError("Failed to register the user");
    }
  };

  const forgotPassword = async () => {
    if (emailField.current.value.length == 0) {
      setError("Please enter required details");
      return;
    }

    try {
      const response = await axiosGlobal.post("/api/v1/user/forgotpassword", {
        email: emailField.current.value,
      });
      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304 ||
        response.status == 201 ||
        response.statusText == "Created"
      ) {
        setIsRegisterMode(false);
        setSuccess(response.data);
      } else {
        setError("Please check username/email id");
      }
    } catch (e) {
      setError(e.response?.data?.message ?? "Something went wrong");
    }
  };

  const resetPassword = async () => {
    if (passwordField.current.value != confirmPasswordField.current.value) {
      setError("Password and confirm password doesn't match");
      return;
    }
    try {
      const response = await axiosGlobal.put(
        `/api/v1/user/resetpassword/${uuid}`,
        {
          password: confirmPasswordField.current.value,
        }
      );

      if (
        response.statusText == "OK" ||
        response.status == 200 ||
        response.status == 304
      ) {
        setSuccess(response.data);
        setIsRegisterMode(false);
        setIsForgotMode(false);
        navigate("/login");
        window.location.reload(false);
      } else {
        setError("Couldn't verify");
      }
    } catch (e) {
      setError(e.response?.data?.message ?? "Couldn't verify");
    }

    //navigate('/login')
  };

  const getView = () => {
    if (isLoading) {
      return (
        <ClipLoader
          color="#008ec2"
          loading={isLoading}
          //cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      );
    } else if (isResetMode) {
      return (
        <>
          <div>
            <div
              style={{
                textAlign: "left",
                fontSize: "0.9rem",
                marginBottom: "5px",
              }}
            >
              Password
            </div>
            <input type="password" ref={passwordField} />
          </div>
          <div>
            <div
              style={{
                textAlign: "left",
                fontSize: "0.9rem",
                marginBottom: "5px",
              }}
            >
              Confirm Password
            </div>
            <input type="password" ref={confirmPasswordField} />
          </div>
          <div style={{ color: "red", fontSize: "0.8rem", marginTop: "10px" }}>
            {error}
          </div>
          <div style={{ margin: "10px" }}>
            <Button
              className="w-100"
              onClick={resetPassword}
              style={{ color: "white" }}
            >
              Change Password
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Alert
            show={showRegisterAlert}
            title="Register"
            content="You have not registered yet. Please register first"
            handleModalOk={() => {
              setIsRegisterMode(true);
              setShowRegisterAlert(false);
            }}
            handleModalClose={() => {
              setShowRegisterAlert(false);
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Inter",
              fontSize: "1.5rem",
              fontWeight: 900,
              marginBottom: "30px",
            }}
          >
            <div style={{ marginRight: "20px", cursor: "pointer" }}>
              <Image
                src={arrowLeft}
                width={18}
                style={{ marginRight: "7px" }}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div>
              {isRegisterMode
                ? "Register"
                : isForgotMode
                ? "Forgot Password"
                : "Login"}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "40px",
                //marginTop: "20px",
                fontSize: "0.7rem",
                marginLeft: "auto",
              }}
            >
              {isRegisterMode
                ? "Already a member?"
                : !isForgotMode
                ? "Not a member yet?"
                : ""}
              <a
                className="links blue-link"
                style={{ marginLeft: "5px" }}
                onClick={
                  isRegisterMode
                    ? () => {
                        if (emailField.current) emailField.current.value = "";
                        if (passwordField.current)
                          passwordField.current.value = "";
                        setIsRegisterMode(false);
                        setIsForgotMode(false);
                      }
                    : () => {
                        if (emailField.current) emailField.current.value = "";
                        if (passwordField.current)
                          passwordField.current.value = "";
                        setIsRegisterMode(true);
                        setIsForgotMode(false);
                      }
                }
              >
                {isRegisterMode ? "Login" : "Register"}
              </a>{" "}
            </div>
          </div>
          {!isRegisterMode && (
            <div>
              <div className="login-form-text">
                {!isRegisterMode || isForgotMode
                  ? "Username or Email Address"
                  : "Username"}
              </div>
              <input
                type="text"
                value={oauthProfile.current?.user_email}
                ref={emailField}
                //disabled={isRegisterMode && oauthProfile.current}
              />
            </div>
          )}

          {isRegisterMode && !isForgotMode ? (
            <>
              <div>
                <div className="login-form-text">Email Address</div>
                <input
                  type="text"
                  value={oauthProfile.current?.user_email}
                  ref={emailField}
                  disabled={isRegisterMode && oauthProfile.current}
                />
              </div>
            </>
          ) : null}
          {!isForgotMode ? (
            <div>
              <div className="login-form-text">Password</div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #d8dadc",
                  borderRadius: "5px",
                  padding: "0px 10px",
                }}
              >
                <input
                  className="login-form-password"
                  type={passwordVisible ? "text" : "password"}
                  ref={passwordField}
                  style={{ border: "none" }}
                />
                <div>
                  <i
                    class={`fa ${passwordVisible ? "fa-eye" : "fa-eye-slash"}`}
                    aria-hidden="true"
                    onClick={() => setPasswordVisible((val) => !val)}
                  ></i>
                </div>
              </div>
            </div>
          ) : null}

          <div
            style={{
              color: success ? "green" : "red",
              fontSize: "0.8rem",
              marginTop: "10px",
            }}
          >
            {success || error}
          </div>
          {!isRegisterMode && !isForgotMode ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                  textAlign: "left",
                  fontSize: "0.8rem",
                  marginTop: "15px",
                }}
              >
                <input
                  type="checkbox"
                  name="rememberMe"
                  value="false"
                  style={{
                    marginRight: "10px",
                  }}
                />
                Remember Me
              </div>
            </div>
          ) : (
            <div
              style={{
                height: "20px",
              }}
            />
          )}
          <div style={{ margin: "10px" }}>
            <Button
              className="w-100 login-button"
              onClick={
                !isRegisterMode
                  ? isForgotMode
                    ? forgotPassword
                    : signin
                  : register
              }
              style={{ color: "white" }}
            >
              {!isRegisterMode
                ? isForgotMode
                  ? "Get New Password"
                  : "Login"
                : "Register"}
            </Button>
            <ToastMesage
              show={showToast}
              message="Registered successfully, Plase login"
              success={true}
              closeToast={() => setShowToast(false)}
            />
          </div>
          {!isRegisterMode && (
            <a
              className="links login-form-text"
              onClick={() => {
                if (emailField.current) emailField.current.value = "";
                if (passwordField.current) passwordField.current.value = "";
                setIsForgotMode(true);
                setIsRegisterMode(false);
              }}
            >
              Lost your password?
            </a>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <div style={{ flex: 1, height: "1px", backgroundColor: "grey" }} />

            <div>
              <p
                style={{
                  flex: 1,
                  width: "50px",
                  height: "10px",
                  textAlign: "center",
                }}
              >
                Or
              </p>
            </div>

            <div style={{ flex: 1, height: "1px", backgroundColor: "grey" }} />
          </div>

          {!isForgotMode && (
            <div style={{ margin: "10px" }}>
              <Button
                className="w-100 login-button"
                onClick={() => {
                  const registerParam = isRegisterMode ? "?register=1" : "";
                  window.location.href = `https://www.limo.org/lock.aspx?app_id=7e9629cd-8244-45ad-89c3-2d34d06eb56a&redirect_uri=https://nlaride.com/login${registerParam}&scope=basic_profile`;
                }}
                style={{ color: "white" }}
              >
                {isRegisterMode ? `Register` : "Login"} with limo.org
              </Button>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={9} md={9} sm={12} xs={12} className="login-hero">
          <div className="login-form">{getView()}</div>
        </Col>
        <Col lg={3} md={3}></Col>
      </Row>
    </Container>
  );
};

export default Auth;
