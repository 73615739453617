import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { Image } from 'react-bootstrap'
import geoalt from '../../assets/images/icons/geo-alt-fill.svg'
import axiosGlobal from '../../utils/axios'

const GoogleAutoSuggest = (props) => {
  const [address, setAddress] = useState(props.value)

  useEffect(() => {
    setAddress(props.value)
  }, [props.value])

  const handleChange = (address) => {
    props.onChange(address)
    setAddress(address)
  }

  const handleSelect = async (address) => {
    //
    let city
    let coords
    try {
      const results = await geocodeByAddress(address)

      const components = results.flatMap((res) => res.address_components)
      console.log(`address components ${JSON.stringify(results)}`)
      let cityEq = components.find((loc) => loc.types.includes('locality'))

      if (!cityEq) {
        cityEq = components.find(
          (loc) =>
            loc.types.includes('political') &&
            !loc.types.includes('administrative_area_level_1') &&
            !loc.types.includes('country'),
        )
      }

      if (!cityEq) {
        cityEq = components.find(
          (loc) =>
            loc.types.includes('establishment') &&
            !loc.types.includes('administrative_area_level_1') &&
            !loc.types.includes('country'),
        )
      }

      cityEq = cityEq?.long_name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const state = results[0].address_components.find((loc) =>
        loc.types.includes('administrative_area_level_1'),
      )?.short_name

      const country = results[0].address_components.find((loc) =>
        loc.types.includes('country'),
      )?.short_name

      const isAirport = results[0].address_components.find((loc) =>
        loc.types.includes('airport'),
      )
        ? true
        : false

      coords = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      }

      let location = ''
      if (cityEq) {
        location += cityEq
      }
      if (state) {
        if (location) {
          location += ', '
        }
        location += state
      }
      if (country) {
        if (location) {
          location += ', '
        }
        location += country
      }

      city = {
        location,
        isAirport: isAirport,
        coordinates: coords,
      }
    } catch {
      console.error('Error while fetching location')
    }

    props.getSelectedValue(city)
    setAddress(address)
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      //searchOptions={{ types: ['airport'] }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div class="wrapper w-100">
          <div className="icon" style={{zIndex: 100}}>
            <Image style={{ color: 'white' }} src={props.icon} />
          </div>
          
          <input
            {...getInputProps({
              placeholder: props.placeholder,
              className: `location-search-input ${props.inputStyle}`,
            })}
            style={{
              backgroundColor: props.bgcolor,
              color: props.color,
            }}
          />
          <div className="autocomplete-dropdown-container">
            {/*loading && <div>Loading...</div>*/}
            {suggestions.map((suggestion) => {
              console.log(`suggestion ${JSON.stringify(suggestion)}`)
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    color: props.containerColor,
                    padding: '5px 20px 5px 20px',
                    fontSize: '0.8rem',
                  }
                : {
                    backgroundColor: '#ffffff',
                    color: props.containerColor,
                    cursor: 'pointer',
                    padding: '5px 20px 5px 20px',
                    fontSize: '0.8rem',
                  }
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: '10px',
                    }}
                    src={geoalt}
                  />
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default GoogleAutoSuggest
