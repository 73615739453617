import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Image } from 'react-bootstrap'
import LighBoxGallary from './lighBoxGallary'
import axiosGlobal from '../../utils/axios'
import ToastMesage from '../../components/companyDetail/toast'

const FilesUploadComponent = (props) => {
  const [gallaryImages, setGallaryImages] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState({})

  useEffect(() => {
    setGallaryImages(props.gallaryImages)
  }, [props.gallaryImages])


  const fileChangeHandler = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const uploadImage = async () => {
    const formData = new FormData()
    formData.append('image', selectedFile)
    formData.append('type', props.type)
    formData.append('post_id', props.post_id)
    formData.append('user_id', props.userId)

    try {
      const response = await axiosGlobal.post(
        '/api/v1/company/upload',
        formData,
        {},
      )
      setShowToast(true)
      if (
        response.statusText == 'OK' ||
        response.status == 200 ||
        response.status == 201 ||
        response.status == 304 ||
        response.statusText == 'Created'
      ) {
        setToastMessage({ success: true, msg: 'Image uploaded successfully' })
        setGallaryImages(images => [...images, {id: response.data.ID, url: `${process.env.REACT_APP_S3_IMAGE_URL}${response.data.file}`}])
      } else {
        setToastMessage({ success: false, msg: "Couldn't upload the image" })
      }
    } catch {
      setToastMessage({ success: false, msg: 'Something went wrong' })
    }
  }

  const markCoverImage = async(id) => {
    console.log(`img ${JSON.stringify(id)}`)
    try {
      const response = await axiosGlobal.put(
        '/api/v1/company/makeCover',
        { id: id, post_id: props.post_id }
      )
  
      if(response.status === 201) {
        console.log(`gal images ${JSON.stringify(gallaryImages)}}`)
        setGallaryImages(images => [...images.map(img => ({...img, isCover: img.id == id ? true : false}))])
        setShowToast(true)
        setToastMessage({ success: true, msg: 'Cover image updated successfully' })
      }
    }
    catch(e) {
      console.log(`Error: updating cover image ${e.message}`)
    }
    
  }

  const deleteGallaryImage = async(img) => {
    try {
      console.log(`delete body ${JSON.stringify({ post_id: props.post_id, file: img })}`)
      const response = await axiosGlobal.delete(
        '/api/v1/company/attachment',
        { data: { post_id: props.post_id, file: img } }
      )
  
      if(response.status === 204) {
        setGallaryImages(images => [...images.filter(i => i.id != img.id)])
        setToastMessage({ success: true, msg: 'Image deleted successfully' })
      }
    }
    catch(e) {
      console.log(`Error: deleting image ${e.message}`)
    }
  }

  const drawPreview = () => {
    if (props.type == 'logo') {
      return (
        <Col lg={6}>
          <Image
            src={
              selectedFile ? URL.createObjectURL(selectedFile) : props.logoUrl
            }
            width={200}
            height={150}
          />
        </Col>
      )
    } else {
      return (
        <>
          {selectedFile ? (
            <Col lg={2}>
              <Image
                src={selectedFile ? URL.createObjectURL(selectedFile) : ''}
                width={200}
                height={150}
              />
            </Col>
          ) : null}

          <Row className="mt-5 mb-5">
            <Col lg={2}></Col>
            <Col lg={10}>
              <div style={{ height: '20rem', overflow: 'scroll' }}>
                <LighBoxGallary images={gallaryImages} height={100} makeCoverHandler={markCoverImage} deleteHandler={deleteGallaryImage} isEditable={true} />
              </div>
            </Col>
          </Row>
        </>
      )
    }
  }

  return (
    <>
      <Col lg={4}>
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 1000,
          }}
        >
          <ToastMesage
            show={showToast}
            message={toastMessage.msg}
            success={toastMessage.success}
            closeToast={() => setShowToast(false)}
          />
        </div>
        <Form.Control
          className="mb-1"
          type="file"
          onChange={fileChangeHandler}
        />
        <div style={{fontSize: '0.8rem', marginBottom: '10px', fontStyle: 'italic'}}>for optimal viewing, upload images of size 1150 px * 450 px or 12 in * 4.5 in</div>
        <Button className="text-white" onClick={uploadImage}>
          Upload
        </Button>
      </Col>
      {drawPreview()}
    </>
  )
}

export default FilesUploadComponent
