import { animated, useSpring } from 'react-spring'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useIntersection } from '../../utils/hooks/useIntersection'
import FeaturedCompanies from '../../components/featuredCompanies/featuredCompanies'
import { useRef } from 'react'

const FeaturedCompaniesSection = () => {
  return (
    <Container fluid className="full-screen-height">
      <Row>
        <div className="search-title text-white left-title">
          Featured Companies
        </div>
      </Row>
      <Row>
        <FeaturedCompanies />
      </Row>
    </Container>
  )
}

export default FeaturedCompaniesSection
