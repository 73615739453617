const INITIAL_STATE = {
  isLoggedIn: false,
  userId: 0,
  userEmail: null,
  userDisplayName: null,
  isAdmin: false,
  token: null,
  listing_id: null,
  is_listing_upgraded: false
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        isLoggedIn: true,
        userId: action.data.userId,
        userEmail: action.data.userEmail,
        userDisplayName: action.data.userDisplayName,
        isAdmin: action.data.isAdmin,
        token: action.data.token,
        listing_id: action.data.listing_id,
        is_listing_upgraded: action.data.is_listing_upgraded
      }

    case 'SIGNOUT':
      return {
        ...state,
        isLoggedIn: false,
      }

    default:
      return state
  }
}

export default authReducer
