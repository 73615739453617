import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, Form, Spinner, Image } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import axiosGlobal from '../../utils/axios'
import { checkAddressEmptyComponents, decodeHtml } from '../../utils/utils'
import vector from '../../assets/images/fc-vector.png'
import './companyListing.css'

class Company {
  constructor(id, image, title, phone, email, city, region, country) {
    this.id = id
    this.image = image
    this.title = title
    this.email = email
    this.phone = phone
    this.city = city
    this.region = region
    this.country = country
  }
}

const CompanyListing = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const featured = new URLSearchParams(location.search).get('featured')

  const allCompanies = useRef([])
  const [companies, setCompanies] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const pageSize = window.innerWidth > 576 ? 6 : 3

  useEffect(() => {
    allCompanies.current = []
  }, [props.params])

  useEffect(() => {
    if (!featured || (featured && Object.keys(props.params).length > 0)) {
      if (allCompanies.current.length > 0 && props.params.isRandom) {
        const companiesForPage = allCompanies.current.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )
        setCompanies(companiesForPage)
      } else {
        fetchCompanies()
      }
    }
  }, [props.params, currentPage])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  const fetchCompanies = async () => {
    const params = { ...props.params }
    console.log(`fetchCompanies --> ${JSON.stringify(params)}`)
    if (params.pickup && params.pickup.isAirport) {
      params[
        'pickup_airport'
      ] = `${params.pickup.coordinates.lat}/${params.pickup.coordinates.lng}`
      delete params.pickup
    } else if (params.pickup && params.pickup.location) {
      if (params.pickup.coordinates) {
        params[
          'coordinates'
        ] = `${params.pickup.coordinates.lat},${params.pickup.coordinates.lng}`
      }
      params['pickup'] = params.pickup.location
    } else {
      delete params.pickup
    }
    if (params.dropoff && params.dropoff.isAirport) {
      params[
        'dropoff_airport'
      ] = `${params.dropoff.coordinates.lat}/${params.dropoff.coordinates.lng}`
      delete params.dropoff
    } else if (params.dropoff && params.dropoff.location) {
      if (params.dropoff.coordinates) {
        params[
          'coordinates'
        ] = `${params.dropoff.coordinates.lat},${params.dropoff.coordinates.lng}`
      }
      params['dropoff'] = params.dropoff.location
    } else {
      delete params.dropoff
    }
    if (params.cityName && params.cityName.isAirport) {
      if (params.cityName.coordinates) {
        params[
          'cityName_airport'
        ] = `${params.cityName.coordinates.lat}/${params.cityName.coordinates.lng}`
      } else if (params.cityName.isAirport && params.cityName.location) {
        // for new search by airport code on home
        params['cityName_airport'] = params.cityName.location
      }

      delete params.cityName
    } else if (params.cityName && params.cityName.location) {
      if (params.cityName.coordinates) {
        params[
          'coordinates'
        ] = `${params.cityName.coordinates.lat},${params.cityName.coordinates.lng}`
      }
      params['cityName'] = params.cityName.location
    } else {
      delete params.cityName
    }

    if(params.companyName) {
      params['companyName'] = encodeURIComponent(params.companyName)
    }

    delete params.default

    const page = params['isRandom'] ? 1 : currentPage
    const limit = params['isRandom'] ? 1000 : pageSize

    var queryString = params
      ? Object.keys(params)
          .filter((key) => params[key])
          .map((key) => key + '=' + params[key])
          .join('&')
      : ''

    if (props.userListing) {
      queryString = `${queryString}&authorId=${props.userId}`
    }
    setIsLoading(true)
    const response = await axiosGlobal.get(
      `/api/v1/company/list?page=${page}&limit=${limit}&${queryString}`,
    )
    setIsLoading(false)
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const ignoreList = []
      const companies = response.data.companies.map((comp) => {
        let attachment = comp.attachment
          .filter(
            (attch) => attch.type == 'images' || attch.type == 'post_images' ||  attch.type == 'cover_image',
          )
          .sort((a, b) => a.ID > b.ID)

        attachment = attachment.length > 0 ? attachment[0] : null

        const featureImage = attachment ? attachment.file : ''

        ignoreList.push(comp.post_id)
        return new Company(
          comp.web_site_member_id,
          featureImage,
          decodeHtml(comp.post_title),
          comp.phone_number,
          comp.email,
          comp.city,
          comp.region,
          comp.country,
        )
      })
      setTotalRecords(response.data.count)

      if (params.isRandom) {
        allCompanies.current = companies
        const companiesForPage = allCompanies.current.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )
        setCompanies(companiesForPage)
      } else {
        setCompanies(companies)
      }
    }
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const getCard = () => {
    const twoDimensionLocations = []
    for (let i = 0; i < companies.length; i) {
      const innerData = []
      for (let x = 0; x < props.noOfColumns; x++) {
        if (companies.length > i) {
          innerData.push(companies[i])
          i++
        }
      }

      twoDimensionLocations.push(innerData)
    }

    const goToCompanyDetails = (id) => {
      navigate(`/company/${id}`)
    }

    let columnProps = { lg: 5, sm: 12, xs: 12 }
    let imageHeight = 700
    switch (props.noOfColumns) {
      case 1:
        columnProps = { lg: 5, sm: 12, xs: 12 }
        break
      case 2:
        columnProps = { lg: 5, sm: 12, xs: 12 }
        break
      case 3:
        columnProps = { lg: 4, sm: 12, xs: 12 }
        break
      case 4:
        columnProps = { lg: 3, md: 3, sm: 6, xs: 12 }
        break
      case 5:
        columnProps = { lg: 2, sm: 12, xs: 12 }
        break
    }

    return twoDimensionLocations.map((comp, index) => (
      <Row className="companySearchGrid" key={index}>
        {comp.map((compCol) => {
          const emails = compCol?.email ? compCol?.email.split(",") : [""];
          const email = emails.length > 0 ? emails[0] : "";
          return (
            <Col {...columnProps} className="mb-3" key={`${compCol.id}`}>
              <div>
                <Card
                  onClick={() => goToCompanyDetails(compCol.id)}
                  className="m-4 mt-2 mb-2 p-4 pt-5 fc-card-main"
                  style={{
                    backgroundColor: '#909090',
                    color: 'white',
                    borderRadius: '10px',
                    boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.3)',
                  }}
                >
                  <div
                    style={{
                      width: '100.6%',
                      backgroundColor: 'white',
                      height: '150px',
                      position: 'absolute',
                      top: '-1px',
                      left: '-1px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    <Image
                      style={{
                        width: '100%',
                        height: '100%',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        //padding: '20px',
                        //objectFit: 'contain',
                      }}
                      src={
                        compCol.image
                          ? `${process.env.REACT_APP_S3_IMAGE_URL}${compCol.image}`
                          : `${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg`
                      }
                    />
                  </div>
                      
                  <Card.Body style={{ marginTop: '120px' }}>
                    <Card.Title>
                      <a className="fc-card-title-link text-white">
                        {compCol.title}
                      </a>
                    </Card.Title>
                    <Card.Text
                      className="text-white"
                      style={{ fontSize: '0.8rem' }}
                    >{checkAddressEmptyComponents(`${compCol.city}, ${compCol.region}, ${compCol.country}`)}</Card.Text>
                    <div style={{ marginTop: '30px' }}>
                      <Card.Text>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            wordBreak: 'break-word',
                          }}
                        >
                          <i className="fa fa-envelope-o fc-icon" />
                          <a className="fc-card-text-link text-white">
                          {email}
                          </a>
                        </div>
                      </Card.Text>
                      <Card.Text>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            wordBreak: 'break-word',
                          }}
                        >
                          <i className="fa fa-phone mr-1 fc-icon" />
                          <a className="fc-card-email-link text-white">
                          {compCol.phone}
                          </a>
                        </div>
                      </Card.Text>
                    </div>
                  </Card.Body>
                  <div className="fc-curvy-line">
                    <img src={vector} style={{ height: '50px' }} />
                  </div>
                </Card>
              </div>
            </Col>
          )
        })}
      </Row>
    ))
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <Container fluid>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Row className="justify-content-center">
            <Col lg={1} md={1} sm={1} xs={1} className="chevron-pre-next"><div className="n" style={{cursor: 'pointer'}} onClick={() => handlePageChange(currentPage-1)}></div></Col>
            <Col lg={10} md={10} sm={9} xs={9} >{getCard()}</Col>
            <Col lg={1} md={1} sm={1} xs={1} className="chevron-pre-next"><div className="a" style={{cursor: 'pointer'}} onClick={() => handlePageChange(currentPage+1)}></div></Col>
          </Row>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px'
            }}
          >
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalRecords}
              pageRangeDisplayed={5}
              onChange={(num) => handlePageChange(num)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </>
      )}
    </Container>
  )
}

export default CompanyListing
