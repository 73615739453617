import { Typeahead, withAsync, Menu, MenuItem } from 'react-bootstrap-typeahead' // ES2015
import { decodeHtml } from '../../utils/utils'
import { Image } from 'react-bootstrap'
import './autoComplete.css'

const AsyncTypeahead = withAsync(Typeahead)

const AutoComplete = ({ initialValue = '', data, onSearch, onSelect }) => {
  return (
    <AsyncTypeahead
      //isLoading = {true}
      defaultInputValue={initialValue}
      labelKey={(option) => `${option.name}`}
      onSearch={onSearch}
      options={data}
      onChange={onSelect}
      placeholder="Enter company name"
      className="no-border"
      renderMenu={(results, menuProps) => (
        <Menu {...menuProps} className="auto-suggest-scroll">
          {results.map((result, index) => (
            <MenuItem option={result} position={index} style={{padding: '5px'}}>
              <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '0.8rem'}}>
                <Image
                  style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  src={
                    result.image
                      ? `${process.env.REACT_APP_S3_IMAGE_URL}/${result.image}`
                      : `${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg`
                  }
                />
                {decodeHtml(result.name)}
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    />
  )
}

export default AutoComplete
