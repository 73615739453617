import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
} from "react-bootstrap";
import headerLogo from "../../assets/images/headerLogo.png";
import "./header.css";

const joinNLA = () => {
  window.lintrk("track", { conversion_id: 7983618 });
};

const Header = (props) => {
  const navigate = useNavigate();
  const isSignin = useSelector((state) => state.auth.isLoggedIn);
  const listing_id = useSelector((state) => state.auth.listing_id);
  const is_listing_upgraded = useSelector(
    (state) => state.auth.is_listing_upgraded
  );

  const headerAnimation = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, -40px)" },
    config: { duration: 700 },
  });

  return (
    <animated.div
      className="main-header notHome"
      style={{
        ...headerAnimation,
        ...props.style,
        backgroundColor: props.showBgColor ? "#040809" : "",
      }}
    >
      <Row className="pt-0">
        <Col
          lg={4}
          md={4}
          sm={4}
          xs={4}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <div className="d-none d-lg-block">
            <img src={headerLogo} height={"100px"} />
          </div>
          <div
            className="d-xs-block d-lg-none"
            style={{ margin: "10px 0px 10px 20px" }}
          >
            <img src={headerLogo} height={"50vw"} />
          </div>
        </Col>
        {!props.isAuthScreen ? (
          <Col lg={8} md={8} sm={8} xs={12} className="mobile-menu-bg">
            <Navbar expand="lg" sticky="top" className="p-0">
              <Navbar.Brand href="#home"></Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="hamburger pr-2"
                style={{
                  backgroundColor: "white",
                  marginTop: "-80px",
                  marginRight: "20px",
                }}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link
                    href="https://www.limo.org/general/register_member_type.asp?"
                    onClick={joinNLA}
                    className="navbar-menu"
                  >
                    Join NLA
                  </Nav.Link>
                  {/*<Nav.Link
                  onClick={() => {
                    navigate('/association')
                    navigate(0)
                  }}
                  className="navbar-menu"
                >
                  Regional Associations
                </Nav.Link>*/}
                  <Nav.Link
                    onClick={() => {
                      navigate("/search");
                      navigate(0);
                    }}
                    className="navbar-menu"
                    style={{
                      fontSize: "1.3rem",
                    }}
                  >
                    <i className="fa fa-search search-button-icon"></i>
                  </Nav.Link>
                  <Nav.Link
                    className="navbar-menu"
                    onClick={() => {
                      navigate("/faq");
                      navigate(0);
                    }}
                  >
                    FAQ's
                  </Nav.Link>
                  {isSignin ? (
                    <Dropdown as={NavItem} className="navbar-menu">
                      <Dropdown.Toggle as={NavLink}>
                        <i className="fa fa-user-circle profile-button-icon m-0"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {listing_id && (
                          <Dropdown.Item
                            onClick={() => {
                              navigate(`/company/${listing_id}?profile=1`);
                              navigate(0);
                            }}
                          >
                            Profile
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            navigate(`/company/${listing_id}`);
                            navigate(0);
                          }}
                        >
                          My Listing
                        </Dropdown.Item>
                        {!is_listing_upgraded && (
                          <Dropdown.Item
                            onClick={() => {
                              window.location.href = `https://form.jotform.com/213116362630142?companyId=${listing_id}&memberId=${listing_id}`;
                            }}
                          >
                            Upgrade my listing
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            navigate("/login");
                            navigate(0);
                          }}
                        >
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Button
                      onClick={() => {
                        navigate("/login");
                        navigate(0);
                      }}
                      className="text-white listing-search-form-apply-btn px-4"
                      style={{
                        margin: '1rem',
                      }}
                    >
                      Sign in
                    </Button>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        ) : null}
      </Row>
    </animated.div>
  );
};

export default Header;
