import { React, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, Image, Row, Col } from 'react-bootstrap'
import axiosGlobal from '../../utils/axios'
import GoogleAutoSuggest from '../../components/autoSuggest/googleAutoSuggest'
import AutoSuggest from '../../components/autoSuggest/autoSuggest'
import { decodeHtml } from '../../utils/utils'
import geoalt from '../../assets/images/icons/geo-alt-home.svg'
import search from '../../assets/images/icons/search.svg'
import './searchSection.css'
import AutoComplete from '../../components/autoSuggest/autoComplete'

class Company {
  constructor(name, image) {
    this.name = name
    this.image = image
  }
}

const SearchSection = () => {
  const navigate = useNavigate()

  const [searchType, setSearchType] = useState(1)
  const [companies, setCompanies] = useState([])

  const selectedCityName = useRef({})
  const companyName = useRef('')

  const fetchComapnies = async (searchTerm) => {
    const response = await axiosGlobal.get(
      `/api/v1/company/search?searchterm=${encodeURIComponent(searchTerm)}`,
    )
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const companies = response.data.map((com) => {
        let attachment = com.attachment
          .filter(
            (attch) => attch.type == 'images' || attch.type == 'post_images' || attch.type == 'cover_image',
          )
          .sort((a, b) => a.ID > b.ID)

        attachment = attachment.length > 0 ? attachment[0] : null

        const featureImage = attachment
          ? attachment.file
          : '2021/10/default.jpg'
        return new Company(com.post_title, featureImage)
      })

      setCompanies(companies)
    }
  }

  const searchCompanies = () => {
    const pickupObj = {
      location: selectedCityName.current.location,
      isAirport: selectedCityName.current.isAirport,
      coordinates: selectedCityName.current.coordinates,
    }

    const stateObj = {
      pickup: pickupObj,
    }

    navigate('/search', {
      state: JSON.stringify(stateObj),
    })
    navigate(0)
  }

  const searchCompaniesByLocation = () => {
    navigate('/search', {
      state: {
        companyName: companyName.current,
      },
    })
    navigate(0)
  }

  const getSearchInput = () => {
    return searchType == 2 ? (
      <AutoComplete 
        data={companies} 
        onSearch={(value) => {
          fetchComapnies(value)
        }}
        onSelect={(value) => {
          companyName.current = value[0].name
        }}
      />
    ) : (
      <GoogleAutoSuggest
        className="w-100"
        inputStyle="location-search-input-no-shadow"
        placeholder="Enter pickup city, address or airport code"
        bgcolor="white"
        color="black"
        containerColor="black"
        icon={geoalt}
        getSelectedValue={(value) => {
          console.log(`getSelectedValue ${JSON.stringify(value)}`)
          selectedCityName.current = value
        }}
        onChange={(value) => {
          console.log(`onChange ${JSON.stringify(value)}`)
          selectedCityName.current = value
        }}
        value={selectedCityName.current.location ?? ''}
      />
    )
  }

  return (
    <Row className="d-flex justify-content-center" style={{}}>
      <Col lg={3} md={3}  style={{zIndex: 1000, backgroundColor: 'white', padding: '7px', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}} className="d-flex d-none d-md-block">
        <Dropdown className="h-100 w-100" onSelect={(key, _) => setSearchType(key)}>
          <Dropdown.Toggle
            className="h-100 w-100"
            style={{ backgroundColor: 'white', color: '#686868', fontSize: '0.9rem', border: 'none', borderRadius: 0, borderRight: '1px solid #E8E8E8' }}
          >
            {searchType == 2 ? 'Companies' : 'Location'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={1}>Location</Dropdown.Item>
            <Dropdown.Item eventKey={2}>Companies</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col lg={3} md={3} sm={4} xs={4}  style={{zIndex: 1000, backgroundColor: 'white', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', padding: '7px'}} className="d-flex d-block d-md-none">
        <Dropdown className="h-100 w-100" onSelect={(key, _) => setSearchType(key)}>
          <Dropdown.Toggle
            className="h-100 w-80"
            style={{ backgroundColor: 'white', color: '#686868', fontSize: '0.7rem', border: 'none', borderRadius: 0, borderBottom: '0px', borderRightWidth: '0px' }}
          >
            {searchType == 2 ? 'Companies' : 'Location'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={1}>Location</Dropdown.Item>
            <Dropdown.Item eventKey={2}>Companies</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col lg={7} md={7} sm={8} xs={8} className="search-input" style={{zIndex: 1000, position: 'relative'}}>
        {getSearchInput()}
      </Col>
      <Col lg={2} md={2} sm={5} xs={5}>
       <div className="d-block d-md-none" style={{height: '10px'}}></div>
        <Button
          lg={2}
          className="w-100 h-100 search-btn"
          onClick={
            searchType == 2 ? searchCompaniesByLocation : searchCompanies
          }
        >
          <div className="d-flex" style={{marginLeft: '7px'}}>
            Search{' '}
            <i
              className="fa fa-search search-button-icon search-btn-icon"
            ></i>
          </div>
        </Button>
      </Col>
    </Row>
  )
}

export default SearchSection
