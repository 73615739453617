import React, { useState } from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'

const ToastMesage = (props) => {
  return (
    <Toast
      onClose={() => props.closeToast()}
      show={props.show}
      delay={2000}
      autohide
      bg={props.success ? 'success' : 'danger'}
    >
      <Toast.Body className="text-white">{props.message}</Toast.Body>
    </Toast>
  )
}

export default ToastMesage
