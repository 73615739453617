import React from 'react'
import Faq from 'react-faq-component'
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap'
import './faq.css'

const faqDataSection1 = {
  title: 'Getting Started',
  rows: [
    {
      title: 'How do I set up my company listing?',
      content: `To claim your listing, follow these steps:<br>
      1. Sign in to your account by entering your login credentials<br>
      2. On the homepage, change the search criteria to "Companies" and enter the name of your company. Alternatively, go to the Browse page and filter for your company name<br>
      3. Click on your Company card from the search results<br>
      4. Scroll down on the page and click on the "Claim listing" button<br>
      5. A pop up will check if you'd like to send a claim listing to the NLA admin. Click "Send"<br>
      6. An NLA admin will approve your request (if valid)<br>
      `,
    },
    {
      title: 'Why does my username & password not work?',
      content: 'Please select the ‘Forgot Password’ button and follow the instructions. Your username is the email address associated with your NLA account; you can reset your password accordingly. ',
    },
    {
      title: 'What do I do if I can’t find my company on NLARide?',
      content: 'You should first confirm that your NLA membership is active. Only active paid members will appear on NLARide. If your membership is active, be sure that your limo.org membership profile information matches what you are searching on NLARide. You will only appear in searches with criteria in your membership profile. If you are still unable to locate your company listing, please contact <a href="mailto:info@limo.org"><u>info@limo.org</u></a> for assistance.',
    },
  ],
}
const faqDataSection2 = {
  title: 'Member Profile Pages',
  rows: [
    {
      title: 'How do I edit my member profile page?',
      content: 'Once you’re logged in, you can go to the top right and click ‘My Listings’, scroll down to the right-hand side and hit ‘Edit’.',
    },
    {
      title: 'What are best practices when completing my profile page?',
      content: 'Ensure each section of your profile is filled out correctly. This information is pulled directly from your limo.org membership profile. Be sure that all of your contact information, fleet information, software, etc. is updated. Don’t forget to add images of your fleet!',
    },
    {
      title: 'What are ways I can optimize my profile page?',
      content: 'You can optimize your profile page by upgrading to Premium, adding more locations, and adding as much information to your profile as possible. This will make your listing more appealing.  You can also share your page URL on your company website, social media, email signature, etc.',
    },
    {
      title: 'Is there a limit to how many images I can add to my member profile page?',
      content: 'No, there is not a limit on the number of images. However, there is a limit on the size of each image, this is 2mb.',
    },
    {
      title: 'What are the best dimensions for images I want to add to my profile?',
      content: 'For optimal viewing, upload images of size 1150px * 450px or 12 in * 4.5 in',
    },
    {
      title: 'Can I change my member profile image?',
      content: 'Yes, you can. Once you click ‘Edit’ on your page, it is the first drop box available.',
    },
    {
      title: 'How do I share my page on social media?',
      content: 'You simply copy and paste your URL onto your social platforms.',
    },
  ],
}

const faqDataSection3 = {
  title: 'Search',
  rows: [
    {
      title: 'How do I know where my company will appear in a Search?',
      content: 'Your company will appear in the search results based on the information you have included in your limo.org membership profile.',
    },
    {
      title: 'What locations will my listing be featured in?',
      content: 'You will be featured in all locations listed in your “Cities Serviced” section of your limo.org membership profile. Please note you will not automatically be featured in the location listed as your mailing/billing address. For example, if your mailing address is New York City, NY but you do not list New York City under “Cities” Serviced”, you will not appear in the search of New York City.',
    },
    {
      title: 'What happens when a customer contacts me from the website?',
      content: "You will receive an email from <a href='mailto:noreply@nlaride.com<'><u>noreply@nlaride.com</u></a> and the subject will be '[NLA Ride] Enquiry' Please do not forget to check junk also.",
    }
  ],
}

const faqDataSection4 = {
  title: 'Upgrading to Premium',
  rows: [
    {
      title: 'How can I make sure that my company appears at the top of a Search?',
      content: 'All search results appear in a random order. To be automatically featured first, you must become a Premium NLARide member. This will ensure you appear at the top on your specific locations.',
    },
    {
      title: 'What is a premium membership?',
      content: 'A premium membership allows you to appear at the top of the location-based search if you operate in that specific area.',
    },
    {
      title: 'How do I upgrade to a premium membership on NLARide.com?',
      content: `1. Sign in to your account by entering your login credentials<br>
      2. On the homepage, change the search criteria to "Companies" and enter the name of your company. Alternatively, go to the Browse page and filter for your company name<br>
      3. Click on your Company card from the search results<br>
      4. Scroll down on the page and click on the "Upgrade listing" button<br>
      5. On the page that opens up, enter all the details that are required including your credit card details. Submit.<br>
      6. Once your payment is processed, your listing will automatically be upgraded and will appear in the "Premium listings" section of the homepage<br>
      `,
    },
    {
      title: 'How much does a premium upgrade on NLARide.com cost?',
      content: ' It costs $199 for the year.',
    },
  ],
}

const faqDataSection5 = {
  title: 'General Questions',
  rows: [
    {
      title: 'Who do I contact for assistance with my profile information?',
      content: 'Please contact <a href="mailto:info@limo.org"><u>info@limo.org</u></a> or <a href="tel:(800) 652-7007"><u>(800) 652-7007</u></a> for assistance.',
    },
    {
      title: 'How can I add more city listings to my profile?',
      content: 'You can login to limo.org and update your “Cities Serviced” listings. You receive two complimentary city listings with your membership and can add up to ten more listings at $35 per listing. You can also include up to five airport codes at no cost.',
    },
  ],
}

const faqData = [faqDataSection1, faqDataSection2, faqDataSection3, faqDataSection4, faqDataSection5]

const FaqList = () => {
  return (
    <Container fluid className="detail-container mb-5">
      <Row className="align-items-center listing-hero">
        <div className="detail-title" style={{ bottom: '30px' }}>
          FAQ's
        </div>
      </Row>
      <Row>
        {
          faqData.map(data => <Faq data={data} />)
        }
        
      </Row>
    </Container>
  )
}

export default FaqList
