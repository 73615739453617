import React from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

const axiosGlobal = axios.create()
const token = localStorage.getItem('token')
axiosGlobal.defaults.headers.common['Authorization'] = `Bearer ${token}`
/*axiosGlobal.interceptors.request.use(
  (config) => {
    config.headers[
      'Authorization'
    ] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IndlYkBjdXJ0aXNnYWJyaWVsLmNvbSIsImlkIjoxLCJpYXQiOjE2NTI4NDY3OTYsImV4cCI6MTY1MjkzMzE5Nn0.daK1rKs_uks3mHWHdbJQHcKhGPoFFNsXKW5065U5YXg`
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)*/

export default axiosGlobal
