import React from 'react'
import { Table } from 'react-bootstrap'

const Association = () => {
  const associationData = [
    {
      name: 'Florida Limousine Association, Inc.',
      link: 'http://www.floridalimousine.com',
    },
    {
      name: 'Greater Atlanta Limousine Association',
      link: 'http://www.galalimo.org',
    },
    {
      name: 'Greater California Livery Assocation',
      link: 'http://www.gcla.org',
    },
    {
      name: 'Illinois Limousine & Bus Association',
      link: 'https://www.illinoislimousineassociation.com/',
    },
    {
      name: 'Limousine Association of New Jersey',
      link: 'http://www.lanj.org',
    },
    {
      name: 'Limousine Association of New York, Inc.',
      link: '',
    },
    {
      name: 'Long Island Limousine Association',
      link: 'http://www.nslalimousne.com',
    },
    {
      name: 'Maryland Limousine Association',
      link: 'https://mdlimoassoc.org/',
    },
    {
      name: 'New England Livery Association (N.E.L.A.)',
      link: 'http://www.nelivery.org',
    },
    {
      name: 'Ohio Chauffeured Transporation Association',
      link: '',
    },
    {
      name: 'Ontario Limousine Owners Association',
      link: 'http://www.oloa.ca',
    },
    {
      name: 'Wisconsin Limousine Association, Inc.',
      link: 'http://www.wisconsinlimo.org',
    },
  ]
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Regional Associations</th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
        {associationData.map((association, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{association.name}</td>
            <td>
              <a target="_blank" href={association.link}>
                {association.link}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default Association
