import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FilesUploadComponent from "../../components/companyDetail/imageUploader";
import LocationPickers from "../../components/companyDetail/locationPickers";
import ToastMesage from "../../components/companyDetail/toast";
import axiosGlobal from "../../utils/axios";
import "./company.css";

const initialState = {
  package_id: "",
  post_title: "",
  post_content: "",
  street: "",
  zip: "",
  fleet_size: "",
  website: "",
  phone_number: "",
  email: "",
  country: "",
  region: "",
  city: "",
  primary_city_serviced: "",
  secondary_city_serviced: "",
  city_serviced_3: "",
  city_serviced_4: "",
  city_serviced_5: "",
  city_serviced_6: "",
  city_serviced_7: "",
  city_serviced_8: "",
  city_serviced_9: "",
  city_serviced_10: "",
  city_serviced_11: "",
  city_serviced_12: "",
  airport_serviced_1: "",
  airport_serviced_2: "",
  airport_serviced_3: "",
  airport_serviced_4: "",
  airport_serviced_5: "",
  featured: false,
  web_site_member_id: "",
};

const shoudCitiesServicedUpdate = (obj1, obj2) => {
  if (
    obj1.primary_city_serviced != obj2.primary_city_serviced ||
    obj1.secondary_city_serviced != obj2.secondary_city_serviced ||
    obj1.city_serviced_3 != obj2.city_serviced_3 ||
    obj1.city_serviced_4 != obj2.city_serviced_4 ||
    obj1.city_serviced_5 != obj2.city_serviced_5 ||
    obj1.city_serviced_6 != obj2.city_serviced_6 ||
    obj1.city_serviced_7 != obj2.city_serviced_7 ||
    obj1.city_serviced_8 != obj2.city_serviced_8 ||
    obj1.city_serviced_9 != obj2.city_serviced_9 ||
    obj1.city_serviced_10 != obj2.city_serviced_10 ||
    obj1.city_serviced_11 != obj2.city_serviced_11 ||
    obj1.city_serviced_12 != obj2.city_serviced_12
  ) {
    return true;
  }
};

const CompanyEditListing = () => {
  let { id } = useParams();
  const user = useSelector((state) => state.auth);
  const [company, setCompany] = useState({});
  const [fields, setFields] = useState(initialState);
  const [showToast, setShowToast] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanyDetail();
  }, []);

  useEffect(() => {
    if (
      !(
        /*company?.posts && user.userId == company?.posts.post_author*/ company?.web_site_member_id ==
          localStorage.getItem("listing_id") || user.isAdmin
      )
    ) {
      if (!company || Object.keys(company).length > 0) {
        navigate(`/company/${id}`);
        return;
      }
    }

    setFields((f) => {
      return {
        package_id: company.package_id,
        post_title: company.post_title,
        post_content: company.post_content,
        street: company.street,
        zip: company.zip,
        fleet_size: company.fleet_size,
        website: company.website,
        phone_number: company.phone_number,
        email: company.email,
        country: company.country ?? "United States",
        region: company.region ?? "California",
        city: company.city ?? "Inglewood",
        primary_city_serviced: company.primary_city_serviced,
        secondary_city_serviced: company.secondary_city_serviced,
        city_serviced_3: company.city_serviced_3,
        city_serviced_4: company.city_serviced_4,
        city_serviced_5: company.city_serviced_5,
        city_serviced_6: company.city_serviced_6,
        city_serviced_7: company.city_serviced_7,
        city_serviced_8: company.city_serviced_8,
        city_serviced_9: company.city_serviced_9,
        city_serviced_10: company.city_serviced_10,
        city_serviced_11: company.city_serviced_11,
        city_serviced_12: company.city_serviced_12,
        airport_serviced_1: company.airport_serviced_1,
        airport_serviced_2: company.airport_serviced_2,
        airport_serviced_3: company.airport_serviced_3,
        airport_serviced_4: company.airport_serviced_4,
        airport_serviced_5: company.airport_serviced_5,
        featured: company.featured,
        web_site_member_id: company.web_site_member_id,
      };
    });
  }, [company]);

  const fetchCompanyDetail = async () => {
    const response = await axiosGlobal.get(`/api/v1/company/get/${id}`);
    if (
      response.statusText == "OK" ||
      response.status == 200 ||
      response.status == 304
    ) {
      setCompany(response.data);
    } else {
      setCompany(null);
    }
  };

  const submitCompanyDetails = async () => {
    const response = await axiosGlobal.put(
      `/api/v1/company/edit/${company?.web_site_member_id}`,
      { ...fields }
    );
    if (response.status == 201) {
      setShowToast(true);

      if (shoudCitiesServicedUpdate(company, fields)) {
        updateCitiesServicedCols();
      }
    }
  };

  const updateCitiesServicedCols = async () => {
    const response = axiosGlobal.get(
      `/api/v1/company/setaddress/${company?.web_site_member_id}`
    );
  };

  const locationChange = (name, value) => {
    setFields((f) => ({ ...f, [name]: value }));
  };

  let logoUrl = "";
  let gallaryImages = [];
  if (company && company?.attachment) {
    const firstLogoUrl = company?.attachment.find(
      (attch) => attch.type == "logo"
    );
    logoUrl = firstLogoUrl ? firstLogoUrl.file : "";
    gallaryImages = company?.attachment
      .filter(
        (attch) =>
          attch.type == "post_images" ||
          attch.type == "cover_image" ||
          attch.type == "images"
      )
      .map((imgData) => ({
        id: imgData.ID,
        url: `${process.env.REACT_APP_S3_IMAGE_URL}${imgData.file}`,
        isCover: imgData.type === "cover_image",
      }));
  }

  const changeInputHandler = (key, value) => {
    setFields((f) => ({ ...f, [key]: value }));
  };

  return (
    <Container fluid>
      <Row className="align-items-center listing-hero">
        <div className="detail-title" style={{ bottom: "30px" }}>
          EDIT LISTING
        </div>
      </Row>
      <h2 className="mt-5 pt-3 edit-listing-title"></h2>
      {user.isAdmin ? (
        <Row className="edit-listing-label">
          <Col lg={2}>Package</Col>
          <Col lg={8}>
            <Form>
              <Form.Check
                type="radio"
                name="group1"
                id="premium"
                label="Premium"
                checked={fields.package_id == 2}
                onChange={(e) => changeInputHandler("package_id", 2)}
              />
              <Form.Check
                type="radio"
                name="group1"
                id="free_place"
                label="Free Place: number of publish days are unlimited (Free)"
                checked={fields.package_id == 1}
                onChange={(e) => changeInputHandler("package_id", 1)}
              />
              <div className="edit-listing-note">Select your package.</div>
            </Form>
          </Col>
        </Row>
      ) : null}
      <Row className="edit-listing-label">
        <Col lg={2}>Logo</Col>
        <FilesUploadComponent
          type="logo"
          post_id={company?.post_id}
          logoUrl={`${process.env.REACT_APP_S3_IMAGE_URL}${logoUrl}`}
          userId={user.userId}
        />
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Place Title</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.post_title}
            onChange={(e) => changeInputHandler("post_title", e.target.value)}
          />
          <div className="edit-listing-note">Enter the title</div>
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Place Description</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            as="textarea"
            rows={5}
            value={fields.post_content}
            onChange={(e) => changeInputHandler("post_content", e.target.value)}
          />
          <div className="edit-listing-note">Enter a description.</div>
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Address</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            as="textarea"
            rows={3}
            value={fields.street}
            onChange={(e) => changeInputHandler("street", e.target.value)}
          />
          <div className="edit-listing-note">Enter address</div>
        </Col>
      </Row>
      {fields.web_site_member_id ? (
        <LocationPickers
          onChange={locationChange}
          country={fields.country}
          region={fields.region}
          city={fields.city}
        />
      ) : null}

      <Row className="edit-listing-label">
        <Col lg={2}>Zip/Post Code</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.zip}
            onChange={(e) => changeInputHandler("zip", e.target.value)}
          />
          <div className="edit-listing-note">
            Please enter listing Zip/Post Code
          </div>
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Images</Col>
        <FilesUploadComponent
          type="images"
          post_id={company?.post_id}
          gallaryImages={gallaryImages}
          userId={user.userId}
        />
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Fleet Size</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.fleet_size}
            onChange={(e) => changeInputHandler("fleet_size", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Website</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.website}
            onChange={(e) => changeInputHandler("website", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Phone Number</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="number"
            value={fields.phone_number}
            onChange={(e) => changeInputHandler("phone_number", e.target.value)}
          />
          <div className="edit-listing-note">
            You can enter the contact email for your listing.
          </div>
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Email</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="email"
            value={fields.email}
            onChange={(e) => changeInputHandler("email", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Primary City Serviced</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.primary_city_serviced}
            onChange={(e) =>
              changeInputHandler("primary_city_serviced", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Secondary City Serviced</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.secondary_city_serviced}
            onChange={(e) =>
              changeInputHandler("secondary_city_serviced", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 3</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_3}
            onChange={(e) =>
              changeInputHandler("city_serviced_3", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 4</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_4}
            onChange={(e) =>
              changeInputHandler("city_serviced_4", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 5</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_5}
            onChange={(e) =>
              changeInputHandler("city_serviced_5", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 6</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_6}
            onChange={(e) =>
              changeInputHandler("city_serviced_6", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 7</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_7}
            onChange={(e) =>
              changeInputHandler("city_serviced_7", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 8</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_8}
            onChange={(e) =>
              changeInputHandler("city_serviced_8", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 9</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_9}
            onChange={(e) =>
              changeInputHandler("city_serviced_9", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 10</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_10}
            onChange={(e) =>
              changeInputHandler("city_serviced_10", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 11</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_11}
            onChange={(e) =>
              changeInputHandler("city_serviced_11", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>City Serviced 12</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.city_serviced_12}
            onChange={(e) =>
              changeInputHandler("city_serviced_12", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Airport Serviced 1</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.airport_serviced_1}
            onChange={(e) =>
              changeInputHandler("airport_serviced_1", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Airport Serviced 2</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.airport_serviced_2}
            onChange={(e) =>
              changeInputHandler("airport_serviced_2", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Airport Serviced 3</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.airport_serviced_3}
            onChange={(e) =>
              changeInputHandler("airport_serviced_3", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Airport Serviced 4</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.airport_serviced_4}
            onChange={(e) =>
              changeInputHandler("airport_serviced_4", e.target.value)
            }
          />
        </Col>
      </Row>
      <Row className="edit-listing-label">
        <Col lg={2}>Airport Serviced 5</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.airport_serviced_5}
            onChange={(e) =>
              changeInputHandler("airport_serviced_5", e.target.value)
            }
          />
        </Col>
      </Row>
      {user.isAdmin && (
        <Row className="edit-listing-label">
          <Col lg={2}></Col>
          <Col lg={8}>
            <Form>
              <Form.Check
                type="checkbox"
                id="premium"
                label="Is Featured?"
                checked={fields.featured}
                value={fields.featured}
                onChange={(e) =>
                  changeInputHandler("featured", e.currentTarget.checked)
                }
              />

              <div className="edit-listing-note">
                Mark listing as a featured.
              </div>
            </Form>
          </Col>
        </Row>
      )}

      <Row className="edit-listing-label">
        <Col lg={2}>Web Site Member ID</Col>
        <Col lg={10}>
          <Form.Control
            className="mb-1"
            type="text"
            value={fields.web_site_member_id}
            onChange={(e) =>
              changeInputHandler("web_site_member_id", e.target.value)
            }
          />
        </Col>
      </Row>
      {showToast ? (
        <Row>
          <ToastMesage
            show={showToast}
            message="Listing updated successfully"
            success={true}
            closeToast={() => setShowToast(false)}
          />
        </Row>
      ) : null}

      <Row className="edit-listing-label">
        <Col lg={2}></Col>
        <Col lg={10}>
          <Button
            variant="primary"
            type="submit"
            className="mt-3 mb-3 text-white"
            onClick={submitCompanyDetails}
          >
            Submit Listing
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyEditListing;
