import React, { useState, useRef } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import axiosGlobal from '../../utils/axios'

const ClaimListing = (props) => {
  /*const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)*/

  const companyNameField = useRef('')
  const emailField = useRef('')
  const memberIdField = useRef('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const sendClaim = async () => {
    if (
      !companyNameField.current.value ||
      !emailField.current.value ||
      !memberIdField.current.value
    ) {
      setError('All fields are required')
      return
    }

    try {
      const response = await axiosGlobal.post('/api/v1/company/claimlisting/', {
        claimemail: emailField.current.value,
        memberid: memberIdField.current.value,
      })
      if (
        response.statusText == 'OK' ||
        response.status == 200 ||
        response.status == 304 ||
        response.statusText == 'Created'
      ) {
        setSuccess('Verification email sent')
        //props.handleModalClose()
      } else {
        setError("Couldn't sent the claim")
      }
    } catch {
      setError('Somethiong went wrong')
    }
  }

  return (
    <Modal show={props.show} onHide={props.handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Claim Listing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Listing Title</Form.Label>
            <Form.Control
              type="text"
              value={props.company.post_title}
              disabled
              ref={companyNameField}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>NLA user email</Form.Label>
            <Form.Control
              type="email"
              value={props.userEmail}
              disabled
              ref={emailField}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Member ID</Form.Label>
            <Form.Control
              type="number"
              value={props.company.web_site_member_id}
              disabled
              ref={memberIdField}
            />
          </Form.Group>
          <Form.Text className={!success ? 'text-danger' : 'text-success'}>
            {success || error}
          </Form.Text>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="text-white" onClick={sendClaim}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ClaimListing
