import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { animated, useSpring } from 'react-spring'
import { useIntersection } from '../../utils/hooks/useIntersection'
/*import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'*/
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import axiosGlobal from '../../utils/axios'
import fc_previous from '../../assets/images/chevron-previous.png'
import fc_next from '../../assets/images/chevron-next.png'
import './events.css'

const Events = () => {
  const ref = useRef()
  const isVisible = useIntersection(ref, '500px')

  const noOfColumns = window.innerWidth > 576 ? 3 : 1
  const pageSize = window.innerWidth > 576 ? 10 : 5

  const navigate = useNavigate()

  const allEvents = useRef([])
  const [events, setEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchEvents()
  }, [])

  useEffect(() => {
    const d = document.querySelector('.events-section')
    const classes = d.className.split(' ')
    if (isVisible) {
      if (d) {
        const index = classes.indexOf('events-section-scroll')
        if (index > -1) {
          // only splice array when item is found
          classes.splice(index, 1) // 2nd parameter means remove one item only
        }
        classes.push('events-section-noscroll')
        d.className = classes.join(' ')
        setTimeout(() => {
          const index = classes.indexOf('events-section-noscroll')
          if (index > -1) {
            // only splice array when item is found
            classes.splice(index, 1) // 2nd parameter means remove one item only
          }
          classes.push('events-section-scroll')
          d.className = classes.join(' ')
        }, 1000)
      }
    } else {
      if (d) {
        const index = classes.indexOf('events-section-scroll')
        if (index > -1) {
          // only splice array when item is found
          classes.splice(index, 1) // 2nd parameter means remove one item only
        }
        classes.push('events-section-noscroll')
        d.className = classes.join(' ')
      }
    }
  }, [isVisible])

  useEffect(() => {
    if (allEvents.current.items?.length > 0) {
      const eventsForPage = allEvents.current.items?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
      )
      setEvents(eventsForPage)
    }
  }, [currentPage])

  const fetchEvents = async () => {
    const response = await axiosGlobal.get('/api/v1/event/list')
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {

      allEvents.current = response.data

      setTotalRecords(allEvents.current.items.length)

      const eventsForPage = allEvents.current.items?.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
      )

      setEvents(eventsForPage)
    }
  }

  const getEventId = (link) => {
    const url = new URL(link)
    return url.searchParams.get('id')
  }
  const addEventToCal = (link) => {
    window.open(
      `https://www.limo.org/general/ics.aspx?id=${getEventId(link)}`,
      '_blank',
    )
  }
  const gotToEventPage = (link) => {
    window.open(link, '_blank')
  }

  const getFormattedDate = (dt) => {
    const dateStr = new Date(dt).toISOString()
    const date = new Date(dateStr)

    const doubleDigit = (val) => {
      if (val < 10) {
        val = '0' + val
      }
      return val
    }

    let hours = doubleDigit(date.getUTCHours());
    const minutes = doubleDigit(date.getUTCMinutes());
    const amPM = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    return (
      doubleDigit(date.getUTCMonth() + 1) +
      '/' +
      doubleDigit(date.getUTCDate()) +
      '/' +
      date.getUTCFullYear() +
      '||' +
      hours +
      ':' +
      minutes + 
      ' ' +
      amPM
    )
  }

  const randomColors = ['#ECEFF8', '#F8ECF8', '#F8ECEC', '#EEF8EC']

  const listTile = (event, index) => {
    const dateTime = getFormattedDate(event.pubDate).split('||')
    const colorIndex =
      index < randomColors.length ? index : index % randomColors.length
    return (
      <Row className="list-tile-row" onClick={() => gotToEventPage(event.link)}>
        <Col lg={2} md={12} sm={12} xs={12}>
          <div
            className="tile-datetimebox"
            style={{ backgroundColor: randomColors[colorIndex] }}
          >
            <div>{dateTime[0]}</div>
            <div>{dateTime[1]}</div>
          </div>
        </Col>
        <Col lg={7} md={12} sm={12} xs={12}>
          <div className="tile-content">
            <div className="">{event.title}</div>
            <div>{event.description}</div>
          </div>
        </Col>
        <Col lg={3} md={12} sm={12} xs={12}>
          <div className="tile-calendar">
            <Button>Add to Calendar</Button>
          </div>
        </Col>
      </Row>
    )
  }

  const handlePageChange = (pageNumber) => {
    console.log(`handlePageChange ${pageNumber}`)
    setCurrentPage(pageNumber)
  }

  return (
    <Container fluid>
      <Row className="p-5 pt-3 pb-0 justify-content-center" ref={ref}>
        <Col lg={9} md={10} sm={11} xs={11}>
          {events.map((event, index) => listTile(event, index))}
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={pageSize}
          totalItemsCount={totalRecords}
          pageRangeDisplayed={5}
          onChange={(num) => handlePageChange(num)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </Container>
  )
}

export default Events
