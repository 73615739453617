import { animated, useTrail, useSpring } from 'react-spring'
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Link, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import img from '../../assets/images/banner.png'
import featuredCompaniesBanner from '../../assets/images/fc_banner.png'
import img1 from '../../assets/images/vector.png'
import img2 from '../../assets/images/path.gif'
import './home.css'
import SearchSection from './searchSection'
import FeaturedCompaniesSection from './featuredCompaniesSection'
//import FeaturedLocationsSection from './featuredLocationsSection'
//import OurBlogsSection from './ourBlogsSection'
import EventSection from './eventSection'
//import { useEffect, useRef } from 'react'
//import { Parallax } from 'react-scroll-parallax'
import ReactFullpage from '@fullpage/react-fullpage'
//import ReactPageScroller, { SectionContainer } from 'react-page-scroller'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'

const Home = () => {
  const navigate = useNavigate()

  const height = window.innerHeight

  const componentFactorHeight = (offset, shouldAdjust) => {
    if (height < 600) {
      return `${100 + offset}vh`
    } else if (height < 720) {
      return shouldAdjust ? '95vh' : '90vh'
    }
    return '100vh'
  }

  const isSignin = useSelector((state) => state.auth.isLoggedIn)

  const trail = useTrail(
    3,
    {
      opacity: 1,
      transform: 'translate(0px, 0px)',
      from: { opacity: 0, transform: 'translate(0px, 100px)' },
      config: { duration: 500 },
    },
    {
      opacity: 1,
      transform: 'translate(0px, 0px)',
      from: { opacity: 0, transform: 'translate(0px, 100px)' },
      config: { duration: 200 },
    },
    {
      opacity: 1,
      transform: 'translate(0px, 0px)',
      from: { opacity: 0, transform: 'translate(0px, 100px)' },
      config: { duration: 200 },
    },
  )

  const searchFieldAnimation = useSpring({
    opacity: 1,
    delay: 1500,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, -60px)' },
    config: { duration: 1000 },
  })

  const textComps = [
    <div className="home-title-main">WELCOME TO</div>,
    <div className="home-title-subtitle ">NLA RIDE</div>,
    <div className="home-search-title">
      Find ground transportation providers now!
    </div>,
  ]

  const homeSection = () => {
    return (
      <Container fluid className="full-screen-height">
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              marginTop: window.innerWidth < 768 ? '80px' : '0px',
            }}
          >
            <div className="mb-5 position-relative" style={{zIndex: 2000}}>
              <Header/>
            </div>
            <div className='d-none d-lg-block' style={{height: '20px'}}></div>
            {trail.map((styles, index) => (
              <animated.div style={styles}>{textComps[index]}</animated.div>
            ))}
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={6} md={6} sm={10} xs={10}>
            <animated.div style={searchFieldAnimation}><SearchSection /></animated.div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <ReactFullpage
      //debug
      //licenseKey={'YOUR_KEY_HERE'}

      //scrollOverflow={true} //false but changed for events scroll
      //scrollingSpeed={1500} /* Options here */
      //normalScrollElements=".auto-suggest-scroll"

      scrollOverflow={false} //false but changed for events scroll
      scrollingSpeed={1000} /* Options here */
      normalScrollElements=".auto-suggest-scroll .event-section"

      //keyboardScrolling
      //lazyLoading={false}
      
      render={({ state, fullpageApi }) => {
        return (
          <div>
            <ReactFullpage.Wrapper>
              {/*<div key="section1" className="navbar-main section">
                <Header showBgColor={true} />
              </div>*/}
              <div key="section1" className="main-hero section">
                {homeSection()}
              </div>
              {/*
                  <div key="section3" className="section">
                    <SearchSection />
                  </div>
                */}

              <div key="section2" className="fc-hero section">
                <FeaturedCompaniesSection />
              </div>
              <div key="section3" className="events-hero section">
                <EventSection />
              </div>
              {/*<div key="section3" className="ob-hero section">
                <OurBlogsSection />
              </div>*/}
              <div key="section4" className="section">
                <Footer />
              </div>
            </ReactFullpage.Wrapper>
          </div>
        )
      }}
    />
  )
}

export default Home
