export const decodeHtml = (html) => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const checkAddressEmptyComponents = (address) => {
  const addressComponents = address.split(', ')
  return addressComponents.filter(ac => ac && ac != 'null' && ac != 'NULL').join(', ')
}
