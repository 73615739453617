import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { Container, Row, Col, Card, Image, CardGroup } from "react-bootstrap";
import { useIntersection } from "../../utils/hooks/useIntersection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axiosGlobal from "../../utils/axios";
import { decodeHtml } from "../../utils/utils";
import ellipse1 from "../../assets/images/ellipse1.png";
import fc_previous from "../../assets/images/chevron-previous.png";
import fc_next from "../../assets/images/chevron-next.png";
import vector from "../../assets/images/fc-vector.png";
import "./featuredCompanies.css";

class Company {
  constructor(id, image, logoUrl, title, email, phone, city, region, country) {
    this.id = id;
    this.image = image;
    this.logoUrl = logoUrl;
    this.title = title;
    this.email = email;
    this.phone = phone;
    this.city = city;
    this.region = region;
    this.country = country;
  }
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group carousel-buttons">
      {" "}
      <img
        src={fc_previous}
        onClick={() => previous()}
        height={40}
        style={{
          cursor: "pointer",
        }}
      />
      <img
        src={fc_next}
        onClick={() => next()}
        height={40}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

const FeaturedCompanies = (props) => {
  const ref = useRef();
  const isVisible = useIntersection(ref, "100px");

  useEffect(() => {
    fetchFeaturedCompanies();
  }, []);

  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);

  const cardAnimations = [
    useSpring({
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translate(0px, 0px)" : "translate(0px, 250px)",
      from: { opacity: 0, transform: "translate(0px, 300px)" },
      config: { duration: 500 },
    }),
    useSpring({
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translate(0px, 0px)" : "translate(0px, 250px)",
      from: { opacity: 0, transform: "translate(0px, 300px)" },
      config: { duration: 900 },
    }),
    useSpring({
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translate(0px, 0px)" : "translate(0px, 250px)",
      from: { opacity: 0, transform: "translate(0px, 300px)" },
      config: { duration: 1300 },
    }),
  ];

  const fetchFeaturedCompanies = async () => {
    const response = await axiosGlobal.get(
      "/api/v1/company/list?page=1&limit=100&featured=1&isRandom=1"
    );
    if (
      response.statusText == "OK" ||
      response.status == 200 ||
      response.status == 304
    ) {
      const companies = response.data.companies.map((comp) => {
        let attachment = comp.attachment
          .filter(
            (attch) =>
              attch.type == "images" ||
              attch.type == "post_images" ||
              attch.type == "cover_image"
          )
          .sort((a, b) => a.ID > b.ID);

        attachment = attachment.length > 0 ? attachment[0] : null;

        const featureImage = attachment ? attachment.file : "";

        const firstLogoUrl = comp.attachment.find(
          (attch) => attch.type == "logo"
        );
        const logoUrl = firstLogoUrl ? firstLogoUrl.file : "";

        return new Company(
          comp.web_site_member_id,
          featureImage,
          logoUrl,
          decodeHtml(comp.post_title),
          comp.email,
          comp.phone_number,
          comp.city,
          comp.region,
          comp.country
        );
      });
      setCompanies(companies);
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const goToCompanyDetails = (id) => {
    navigate(`/company/${id}`);
    navigate(0);
  };

  const cardView = (comp, index) => {
    const emails = comp?.email ? comp?.email.split(",") : [""];
    const email1 = emails.length > 0 ? emails[0] : "";
    return (
      <Card
        onClick={() => goToCompanyDetails(comp.id)}
        className="m-2 p-4 pt-5 fc-card-main"
        style={{
          backgroundColor: "#3E3E3E",
          color: "white",
          borderRadius: "10px",
          boxShadow: "5px 5px 5px 5px rgba(0,0,0,0.3)",
        }}
      >
        <div
          style={{
            width: "100.6%",
            backgroundColor: "white",
            height: "150px",
            position: "absolute",
            top: "-1px",
            left: "-1px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "90%",
              padding: "20px",
              objectFit: "contain",
            }}
            src={
              comp.logoUrl
                ? `${process.env.REACT_APP_S3_IMAGE_URL}${comp.logoUrl}`
                : `${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg`
            }
          />
        </div>

        <Card.Body style={{ marginTop: "120px" }}>
          <Card.Title>
            <a className="fc-card-title-link text-white">{comp.title}</a>
          </Card.Title>
          <Card.Text
            className="text-white"
            style={{ fontSize: "0.8rem" }}
          >{`${comp.city}, ${comp.region}, ${comp.country}`}</Card.Text>
          <div style={{ marginTop: "30px" }}>
            <Card.Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  wordBreak: "break-word",
                }}
              >
                <i className="fa fa-envelope-o fc-icon" />
                <a className="fc-card-text-link text-white">{email1}</a>
              </div>
            </Card.Text>
            <Card.Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  wordBreak: "break-word",
                }}
              >
                <i className="fa fa-phone mr-1 fc-icon" />
                <a className="fc-card-email-link text-white">{comp.phone}</a>
              </div>
            </Card.Text>
          </div>
        </Card.Body>
        <div className="fc-curvy-line">
          <img src={vector} style={{ height: "50px" }} />
        </div>
      </Card>
    );
  };

  const getCard = () => {
    if (props.isGrid) {
      return get2DCard();
    }

    const animatedCards = companies
      .slice(0, 3)
      .map((comp, index) => (
        <animated.div style={cardAnimations[index]}>
          {cardView(comp, index)}
        </animated.div>
      ));

    const normalCards = companies
      .slice(3)
      .map((comp, index) => cardView(comp, index));

    return [...animatedCards, ...normalCards];
  };

  const get2DCard = () => {
    const twoDimensionLocations = [];
    for (let i = 0; i < companies.length; i++) {
      const innerData = [];
      if (companies.length > i) {
        innerData.push(companies[i]);
      }
      if (companies.length > i + 1) {
        innerData.push(companies[i + 1]);
        i++;
      }
      twoDimensionLocations.push(innerData);
    }

    return twoDimensionLocations.map((comp) => {
      const emails1 = comp[0]?.email ? comp[0]?.email.split(",") : [""];
      const email1 = emails1.length > 0 ? emails1[0] : "";

      const emails2 = comp[1]?.email ? comp[1]?.email.split(",") : [""];
      const email2 = emails2.length > 0 ? emails2[1] : "";
      return (
        <Row className="m-4 p-5 pt-3 justify-content-center">
          <Col>
            <div>
              <Card style={{ textAlign: "left" }}>
                <Card.Img
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg)`,
                    backgroundSize: "cover",
                    backgroundPositionY: -50,
                  }}
                  variant="top"
                  src={
                    comp[0].image
                      ? `${process.env.REACT_APP_S3_IMAGE_URL}${comp[0].image}`
                      : `${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg`
                  }
                  height={300}
                />

                <Card.Body>
                  <Card.Title>
                    <a className="fc-card-title-link">{comp[0].title}</a>
                  </Card.Title>
                  <Card.Text>
                    <a className="fc-card-text-link">{comp[0].phone}</a>
                  </Card.Text>
                  <Card.Text>
                    <div style={{ display: "flex" }}>
                      <div className="fc-card-email-lable">Email</div>:{` `}
                      <a className="fc-card-email-link">{email1}</a>
                    </div>
                  </Card.Text>
                  <Card.Text
                    style={{ fontSize: "0.9rem" }}
                  >{`${comp[0].city}, ${comp[0].region}, ${comp[0].country}`}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
          {comp.length > 1 ? (
            <Col>
              <div>
                <Card style={{ textAlign: "left" }}>
                  <Card.Img
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg)`,
                      backgroundSize: "cover",
                      backgroundPositionY: -50,
                    }}
                    variant="top"
                    src={
                      comp[1].image
                        ? `${process.env.REACT_APP_S3_IMAGE_URL}${comp[1].image}`
                        : `${process.env.REACT_APP_S3_IMAGE_URL}/2021/10/default.jpg`
                    }
                    height={300}
                  />
                  <Card.Body>
                    <Card.Title>
                      <a className="fc-card-title-link">{comp[1].title}</a>
                    </Card.Title>
                    <Card.Text>
                      <a className="fc-card-text-link">{comp[1].phone}</a>
                    </Card.Text>
                    <Card.Text>
                      <div style={{ display: "flex" }}>
                        <div className="fc-card-email-lable">Email</div>:{` `}
                        <a className="fc-card-email-link">{email2}</a>
                      </div>
                    </Card.Text>
                    <Card.Text
                      style={{ fontSize: "0.9rem" }}
                    >{`${comp[1].city}, ${comp[1].region}, ${comp[1].country}`}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          ) : null}
        </Row>
      );
    });
  };

  return !props.isGrid ? (
    <Container fluid>
      <Row className="p-5 pt-3 justify-content-center carousel-row" ref={ref}>
        <Carousel
          responsive={responsive}
          showDots={true}
          //dotListClass="carouseldots"
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
          //centerMode={true}
          //infinite={true}
          //autoPlay={isVisible ? true : false}
        >
          {getCard()}
        </Carousel>
      </Row>
    </Container>
  ) : (
    <Container fluid>{getCard()}</Container>
  );
};

export default FeaturedCompanies;
