import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Auth from '../screens/auth'
import Home from '../screens/Home/home'
import Blogs from '../screens/Blogs/blogs'
import MyListings from '../screens/Listings/my_listings'
import Search from '../screens/Listings/search'
import BlogDetail from '../screens/Blogs/blog_detail'
import CompanyDetail from '../screens/Listings/company_detail'
import CompanyEditListing from '../screens/Listings/company_edit_listing'
import Association from '../screens/association'
import VerifyClaim from '../screens/verifyClaim'
import FaqList from '../screens/Faq/faq'

const Content = () => {
  //const isSignin = useSelector((state) => state.auth.isLoggedIn)
  let routes = (
    <>
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/faq" element={<FaqList />} />
      <Route path="/blogDetail/:id" element={<BlogDetail />} />
      <Route path="/companies" element={<Home focus="companies" />} />
      <Route path="/locations" element={<Home focus="locations" />} />
      <Route path="/company/:id" element={<CompanyDetail />} />
      {/*<Route path="/company" element={<MyListings />} />*/}
      <Route path="/verifyclaim/:uuid" element={<VerifyClaim />} />
      <Route path="/companyEditListing/:id" element={<CompanyEditListing />} />
      <Route path="/search" element={<Search />} />
      <Route path="/association" element={<Association />} />
      <Route path="/verify/:uuid" element={<Auth />} />
      <Route path="/resetpassword/:uuid" element={<Auth />} />
      <Route path="/login" element={<Auth />} />
      <Route path="/" element={<Home />} />
    </>
  )
  /*if (!isSignin) {
    routes = <Route path="*" element={<Auth />} />
  }*/
  return <Routes>{routes}</Routes>
}

export default Content
