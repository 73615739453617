import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'
import Nouser from '../../assets/images/no-user.png'
import axiosGlobal from '../../utils/axios'
import './blogs.css'

const BlogDetail = () => {
  const location = useLocation()

  const [blogs, setBlogs] = useState()
  const [currentId, setCurrentId] = useState(location.state.currentBlogId)

  useEffect(() => {
    fetchOurBlogs()
  }, [currentId])

  const fetchOurBlogs = async () => {
    const response = await axiosGlobal.get('/api/v1/blog/list?page=1&limit=50')
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const currentBlogIndex = response.data.findIndex(
        (blog) => blog.ID == currentId,
      )

      const relatedBlogs = {
        currentBlog: response.data[currentBlogIndex],
        previousBlog:
          currentBlogIndex > 0
            ? response.data[currentBlogIndex - 1]
            : response.data[response.data.length - 1],
        nextBlog:
          response.data.length > currentBlogIndex + 1
            ? response.data[currentBlogIndex + 1]
            : response.data[0],
      }

      setBlogs(relatedBlogs)
    }
  }

  const goToDetail = (id) => {
    setCurrentId(id)
  }

  return (
    <Container fluid>
      {blogs ? (
        <>
          <Row
            className="d-flex justify-contents-center align-items-end hero"
            style={{
              backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${process.env.REACT_APP_S3_IMAGE_URL}/${blogs.currentBlog.thumbnail})`,
            }}
          >
            <div>
              <div className="title">{blogs.currentBlog.title}</div>
              <div className="date">
                <Moment format="MMMM d, YYYY">
                  {blogs.currentBlog.created_on}
                </Moment>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={12} sm={8} xs={12} className="p-3 mt-3">
              <div
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: blogs.currentBlog.content,
                }}
              ></div>
            </Col>
          </Row>
          <Row className="m-5 p-5" style={{ textAlign: 'left' }}>
            <Col>
              <Image src={Nouser} roundedCircle width={70} height={70} />
              {blogs.currentBlog.users.display_name}
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              sm={12}
              onClick={() => goToDetail(blogs.previousBlog.ID)}
              style={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${process.env.REACT_APP_S3_IMAGE_URL}/${blogs.previousBlog.thumbnail})`,
              }}
              className="d-flex align-items-center justify-content-end pre-next"
            >
              <div className="pre-next-title-container-right">
                <div className="pre-next-label">Previous Post</div>
                <div className="pre-next-title">{blogs.previousBlog.title}</div>
              </div>
            </Col>
            <Col
              lg={6}
              sm={12}
              onClick={() => goToDetail(blogs.nextBlog.ID)}
              className="d-flex align-items-center justify-content-start pre-next"
              style={{
                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${process.env.REACT_APP_S3_IMAGE_URL}/${blogs.nextBlog.thumbnail})`,
              }}
            >
              <div className="pre-next-title-container-left">
                <div className="pre-next-label">Next Post</div>
                <div className="pre-next-title">{blogs.nextBlog.title}</div>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
    </Container>
  )
}

export default BlogDetail
