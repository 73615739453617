import React, { useEffect, useState } from 'react'
import AutoSuggest from '../autoSuggest/autoSuggest'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Row, Col, Form, Image } from 'react-bootstrap'
import axiosGlobal from '../../utils/axios'
import geoalt from '../../assets/images/icons/geo-alt-fill.svg'
import { decodeHtml } from '../../utils/utils'

const LocationPickers = (props) => {
  const [countryData, setCountryData] = useState([])
  const [country, setCountry] = useState('')

  const [regionData, setRegionData] = useState([])
  const [region, setRegion] = useState('')

  const [cityData, setCityData] = useState([])
  const [city, setCity] = useState('')

  useEffect(() => {
    setCountry(props.country)
  }, [props.country])

  useEffect(() => {
    setRegion(props.region)
  }, [props.region])

  useEffect(() => {
    setCity(props.city)
  }, [props.city])

  useEffect(() => {
    fetchCountries()
  }, [])

  useEffect(() => {
    if (country) {
      fetchRegions(country)
    }
  }, [country])

  useEffect(() => {
    if (region) {
      fetchCities(country, region)
    }
  }, [region])

  const fetchCountries = async () => {
    const response = await axiosGlobal.get('/api/v1/location/get/countries')
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const countries = response.data.map((loc) => ({ name: loc.country }))

      setCountryData(countries)
    }
  }

  const fetchRegions = async (selectedCountry) => {
    const response = await axiosGlobal.get(
      `/api/v1/location/get/regions/${selectedCountry}`,
    )
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const regions = response.data.map((loc) => ({ name: loc.region }))
      
      setRegionData(regions)
    }
  }

  const fetchCities = async (selectedCountry, selectedRegion) => {
    const response = await axiosGlobal.get(
      `/api/v1/location/get/cities/${selectedCountry}/${selectedRegion}`,
    )
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      const cities = response.data.map((loc) => ({ name: loc.city }))

      setCityData(cities)
    }
  }


  console.log(`regionData ${JSON.stringify(regionData)}`)

  return (
    <>
      <Row className="edit-listing-label" style={{zIndex: 1002, position: 'relative'}}>
        <Col lg={2}>Country</Col>
        <Col lg={10}>
          <Form.Group className="mb-3" controlId="formPickUpLocation">
            {/*<AutoSuggest
              id="country"
              icon={geoalt}
              data={countryData}
              placeholder="Pick up Location"
              bgcolor="rgba(255, 255, 255, 0.1)"
              inputStyle={{ border: 'solid 1px #ced4da' }}
              initialVal={props.country}
              renderSuggestion={(suggestion) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    padding: '5px',
                    marginLeft: '-30px',
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: '10px',
                    }}
                    src={geoalt}
                  />
                  {decodeHtml(suggestion.name)}
                </div>
              )}
              onChange={(value) => {
              }}
              getSuggestionValue={(suggestion) => {
                props.onChange('country', suggestion.name)
                setCountry(suggestion.name)
                return suggestion.name
              }}
            />*/}
            <Typeahead
              defaultInputValue={props.country}
              placeholder="Type country"
              onChange={(selected) => {
                // Handle selections...
                console.log(`selected ${JSON.stringify(selected)}`)
                setCountry(selected[0])
                props.onChange('country', selected[0])
              }}
              options={countryData.map(c => c.name)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="edit-listing-label" style={{zIndex: 1001, position: 'relative'}}>
        <Col lg={2}>Region</Col>
        <Col lg={10}>
          <Form.Group className="mb-3" controlId="formPickUpLocation">
            {/*<AutoSuggest
              id="region"
              data={regionData}
              icon={geoalt}
              placeholder="Pick up Location"
              bgcolor="rgba(255, 255, 255, 0.1)"
              inputStyle={{ border: 'solid 1px #ced4da' }}
              initialVal={props.region}
              renderSuggestion={(suggestion) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    padding: '5px',
                    marginLeft: '-30px',
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: '10px',
                    }}
                    src={geoalt}
                  />
                  {decodeHtml(suggestion.name)}
                </div>
              )}
              onChange={(value) => {
               
              }}
              getSuggestionValue={(suggestion) => {
                props.onChange('region', suggestion.name)
                setRegion(suggestion.name)
                return suggestion.name
              }}
            />*/}
            <Typeahead
              defaultInputValue={props.region}
              placeholder="Type region"
              onChange={(selected) => {
                // Handle selections...
                console.log(`onChange`)
                setRegion(selected[0])
                props.onChange('region', selected[0])
              }}
              onFocus={() => console.log(`onFocus`)}
              options={[...regionData.map(c => c.name)]}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="edit-listing-label" style={{zIndex: 1000, position: 'relative'}}>
        <Col lg={2}>City</Col>
        <Col lg={10}>
          <Form.Group className="mb-3" controlId="formPickUpLocation">
            {/*<AutoSuggest
              id="city"
              data={cityData}
              icon={geoalt}
              placeholder="Pick up Location"
              bgcolor="rgba(255, 255, 255, 0.1)"
              inputStyle={{ border: 'solid 1px #ced4da' }}
              initialVal={props.city}
              renderSuggestion={(suggestion) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    padding: '5px',
                    marginLeft: '-30px',
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: '10px',
                    }}
                    src={geoalt}
                  />
                  {decodeHtml(suggestion.name)}
                </div>
              )}
              onChange={(value) => {
                //setPickupSearchTerm(value)
                //fetchLocations(value, false)
              }}
              getSuggestionValue={(suggestion) => {
                props.onChange('city', suggestion.name)
                setCity(suggestion.name)
                return suggestion.name
              }}
            />*/}
            <Typeahead
              defaultInputValue={props.city}
              placeholder="Type city"
              onChange={(selected) => {
                // Handle selections...
                setCity(selected[0])
                props.onChange('city', selected[0])
              }}
              options={cityData.map(c => c.name)}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default LocationPickers
