import React from "react";
import { Modal, Button } from "react-bootstrap";

const Alert = ({ show, title, content, handleModalOk, handleModalClose }) => {
    console.log((`Alert: show ${show}`))
  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="text-white"
          onClick={handleModalOk}
        >
          Register
        </Button>
        <Button variant="secondary" className="text-white" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Alert;
