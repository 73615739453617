import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createStore } from 'redux'
import { combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { configureStore } from 'redux'
import authReducer from './store/auth'
import Layout from './layout'

function App() {
  const rootReducer = combineReducers({
    auth: authReducer,
  })
  const store = createStore(rootReducer)

  return (
    <Provider store={store}>
      <div className="App">
        <Layout />
      </div>
    </Provider>
  )
}

export default App
