import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axiosGlobal from '../utils/axios'

const VerifyClaim = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    verifyClaim()
  }, [uuid])

  const verifyClaim = async () => {
    const response = await axiosGlobal.get(
      `/api/v1/company/verifyclaim/${uuid}`,
    )
    if (
      response.statusText == 'OK' ||
      response.status == 200 ||
      response.status == 304
    ) {
      navigate(`/company/${response.data}`)
    }
  }
}

export default VerifyClaim
