import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './components/header/header'
import Content from './components/content'
import Footer from './components/footer/footer'

import { useSelector, useDispatch } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'

const Layout = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const isSignin = useSelector((state) => state.auth.isLoggedIn)

  useEffect(() => {
    if (!isSignin) {
      const userId = localStorage.getItem('userId')
      const userEmail = JSON.parse(localStorage.getItem('userEmail'))
      const userDisplayName = localStorage.getItem('userDisplayName')
      const isAdmin = localStorage.getItem('isAdmin')
      const token = localStorage.getItem('token')
      const listing_id = localStorage.getItem('listing_id')
      const is_listing_upgraded = localStorage.getItem('is_listing_upgraded')

      if (userId && token) {
        dispatch({
          type: 'SIGNIN',
          data: {
            userId: userId,
            userEmail: userEmail,
            userDisplayName: userDisplayName,
            isAdmin: isAdmin == 'true',
            token: token,
            listing_id: listing_id,
            is_listing_upgraded: is_listing_upgraded == "true",
          },
        })
      }
    } else {
      dispatch({
        type: 'SIGNOUT',
      })
    }
  }, [])

  /*if (!isSignin && token) {
    //dispatch({ type: 'SIGNIN' })
  }*/

  const shouldHideHeader = () => {
    return (
      location.pathname == '/'/* ||
      location.pathname == '/login' ||
      location.pathname.includes('/verify') ||
      location.pathname.includes('/resetpassword')*/
    )
  }

  return (
    <ParallaxProvider>
      {!shouldHideHeader() ? <Header style={{position: 'absolute'}} isAuthScreen={location.pathname == '/login' || location.pathname.includes('/verify') || location.pathname.includes('/resetpassword')} /> : null}
      <Content />
      {!shouldHideHeader() ? <Footer /> : null}
    </ParallaxProvider>
  )
}

export default Layout
