import React, { useState } from "react";
import { Col, Row, Image, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./companyDetail.css";
import trash from "../../assets/images/icons/trash.svg";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
};

const LighBoxGallary = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const confirmDeleteImage = (img) => {
    if (window.confirm("Are you sure?\nPress Ok to continue") == true) {
      props.deleteHandler(img);
      return;
    }
  };

  /*const images = [
    'https://nlaride.com/wp-content/uploads/2022/04/01B609AC-D8BB-4038-B66E-1E48776BEE1C.jpeg',
    'https://nlaride.com/wp-content/uploads/2022/04/F22C69CD-5C53-4C19-8BA3-6E2960F1FE0E.jpeg',
    'https://nlaride.com/wp-content/uploads/2022/04/528C90E3-E7F2-4CB9-BC0D-392CB33341FD.jpeg',
    'https://nlaride.com/wp-content/uploads/2022/04/59F830E2-DDA7-483B-A4D5-9C3F3218C95E.jpeg',
  ]*/

  const openGallary = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div
      className={props.isProfileViewMode ? `col-lg-11 col-sm-12` : ""}
      style={{ textAlign: "left" }}
    >
      <div class="d-flex justify-content-between align-items-center">
        <div className="contact-title gallary-title">Gallery</div>
        {props.isProfileViewMode && (
          <div>
            <Button
              className="text-white listing-search-form-apply-btn px-4"
              onClick={() => props.editHandler()}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      <div className="gallary-slider">
      <Carousel responsive={responsive}>
        {props.images.map((img, index) => (
          <div className="attach-image-wrapper">
            {props.isEditable ? (
              <div className="attach-image-menu-wrapper">
                {!img.isCover ? (
                  <div
                    className="cover-btn"
                    onClick={() => props.makeCoverHandler(img.id)}
                  >
                    Use as cover
                  </div>
                ) : null}
                <i
                  className="fa fa-trash trash-btn"
                  style={{ color: "red" }}
                  onClick={() => confirmDeleteImage(img)}
                ></i>
              </div>
            ) : null}

            <div style={{ objectFit: "cover" }}>
              <img
                src={img.url}
                width={"100%"}
                height={210}
                onClick={() => openGallary(index)}
              />
            </div>
          </div>
        ))}
      </Carousel>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={props.images[photoIndex].url}
          nextSrc={props.images[(photoIndex + 1) % props.images.length].url}
          prevSrc={
            props.images[
              (photoIndex + props.images.length - 1) % props.images.length
            ].url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (index) => (index + props.images.length - 1) % props.images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((index) => (index + 1) % props.images.length)
          }
        />
      )}
    </div>
  );
};

export default LighBoxGallary;
